import {
  faShareFromSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBriefcase,
  faCircle,
  faIndianRupee,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Col, Row,
} from 'reactstrap';
import { useNavigate } from 'react-router';
import './jobs.scss';
import { JobsDataResult } from '../../../services/staffing/job-board/list-jobs/list-jobs.types';
import { AmountSLashTpye, ListJobSchedule, ListJobType } from '../../../utils/constants';

interface TableProps {
  clickedIndex: string;
  data: JobsDataResult[];
  handleGetJobDetails: (jobCode: string, jobIdent: string) => void;
}

const AllPublicJobs: React.FC<TableProps> = ({
  clickedIndex,
  data,
  handleGetJobDetails,
}) => {
  const history = useNavigate();

  const loginPage = () => {
    history('/xcelian/login');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {data.length > 0
        ? (
          <div>
            {/* <div className="my-3 align-center">
              <span className="chip-box">All Jobs</span>
            </div> */}
            <Row className="align-start margin-jobs-pub-card">
              {data.map((jobData, index) => (
                <Col lg="12 my-1" xs="12 my-1" key={`job-info${index.toString()}`}>
                  <Card className="card-joblist-lite-pub">
                    <CardBody className="padding-zero cursor-pointer" onClick={() => handleGetJobDetails(jobData.JobCd, jobData.JobId)}>
                      <Row className="my-1 pub-job-header-bg">
                        <Col xs="9" lg="10">
                          <div className="align-start">
                            <span className={`${clickedIndex === jobData.JobId ? 'pub-job-card-selected' : 'pub-jobs-header-text'}`}>
                              {jobData.nme}
                            </span>
                          </div>
                          <div className="align-start">
                            <span className="pub-jobs-org-text">{jobData.cNme}
                            </span>
                            <span className="pub-jobs-org-text mx-2">|</span>
                            <span className="pub-jobs-org-text">Applicants({jobData.aplCnt})
                            </span>
                            {jobData.isFemale === 'YES' ? (
                              <>
                                <span className="pub-jobs-org-text mx-2">|</span>
                                <CardImg src="/images/icons/female.png" className="pub-job-icon-size1" alt="Icon 3" />
                              </>
                            ) : null}
                            {jobData.isAbled === 'YES' ? (
                              <>
                                <span className="pub-jobs-org-text mx-2">|</span>
                                <CardImg src="/images/icons/diffabled.png" className="pub-job-icon-size1" alt="Icon 3" />
                              </>
                            ) : null}
                          </div>
                        </Col>
                        <Col xs="3" lg="2">
                          <div className="align-end">
                            <CardImg className="pub-job-logo" src={jobData.logoUrl !== '' ? jobData.logoUrl : '/images/icons/jobs-pending.png'} alt="jobs" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="my-1 pt-2">
                        <Col xs="12" lg="12">
                          <div className="align-start">
                            <span className="pub-jobs-org-text">
                              <FontAwesomeIcon icon={faBriefcase} className="mr" />
                              {jobData.expR1} to {jobData.expR2} Yrs
                            </span>
                            <span className="pub-jobs-org-text mx-2">|</span>
                            <span className="pub-jobs-org-text">
                              <FontAwesomeIcon icon={faIndianRupee} className="mr" />
                              {jobData.amnt !== '' ? <>{jobData.amnt} <span className="text-lite-grey">{AmountSLashTpye.find((state) => state.value === jobData.amntTp)?.label || ''}</span></> : 'Not Disclosed'}
                            </span>
                            <span className="pub-jobs-org-text mx-2">|</span>
                            <span className="pub-jobs-org-text">
                              <FontAwesomeIcon icon={faLocationDot} className="mr" />
                              {jobData.city}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <Row className="pub-job-schedule-container pt-3">
                        <Col xs="auto" className="my-1 mx-1">
                          <Card className="pub-chip-box-jobs-pvt">
                            <span className="puser-user-name-text">
                              <FontAwesomeIcon icon={faCircle} className="pub-job-dot" />
                              {ListJobSchedule.find((state) => state.value === jobData.schdl)?.label || ''}
                            </span>
                          </Card>
                        </Col>
                        {jobData.oSchdl && (
                        <Col xs="auto" className="my-1 mx-1">
                          <Card className="pub-chip-box-jobs-pvt">
                            <span className="puser-user-name-text">
                              <FontAwesomeIcon icon={faCircle} className="pub-job-dot" />
                              {jobData.oSchdl}
                            </span>
                          </Card>
                        </Col>
                        )}
                        {jobData.jobTp.length > 0 && jobData.jobTp.map((jobType, jobTpIndex) => (
                          <React.Fragment key={`job-tp${jobTpIndex.toString}`}>
                            <Col xs="auto" className="my-1 mx-1">
                              <Card className="pub-chip-box-jobs-pvt">
                                <span className="puser-user-name-text">
                                  <FontAwesomeIcon icon={faCircle} className="pub-job-dot" />
                                  {ListJobType.find((state) => state.value === jobType)?.label || ''}
                                </span>
                              </Card>
                            </Col>
                          </React.Fragment>
                        ))}
                        {jobData.skills.length > 0 && jobData.skills.map((skill, skillIndex) => (
                          <React.Fragment key={`skilldata-${skillIndex.toString()}`}>
                            <Col xs="auto" className="my-1 mx-1">
                              <Card className="pub-chip-box-jobs-pvt">
                                <span className="puser-user-name-text">
                                  <FontAwesomeIcon icon={faCircle} className="pub-job-dot" />
                                  {skill}
                                </span>
                              </Card>
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </CardBody>
                    <CardFooter className="pub-card-footer">
                      <Row>
                        <Col xs="6" lg="6">
                          <div className="pt-3 mx-1">
                            <span className="text-lite">Vacancies
                              (<span className="puser-user-name-text">{jobData.vacncy}</span>)
                            </span>
                          </div>
                        </Col>
                        <Col xs="6" lg="6">
                          <div className="pt-2 align-end">
                            <Button className="button-icon" onClick={loginPage}>
                              <span className="jobs-apply-text">Apply
                                <FontAwesomeIcon className="mx-1" icon={faShareFromSquare} />
                              </span>
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="table-norecords">
            <span className="table-text-no">No Jobs Found</span>
          </div>
        )}
    </div>
  );
};

export default AllPublicJobs;
