import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Input, Col, Row,
  Button,
} from 'reactstrap';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResumeLoadTypeProps } from '../../../recruiter.type';
import {
  clearResumeLoad,
  clearResumeParser, clearUploadS3FilesReq, getResumeLoad, getResumeParser,
  uploadS3FilesReq,
} from '../../../../../../../store/campusXcel/actions';
import { ResumeParserAddDataInput, ResumeParserOPData } from '../../../../../../../services/staffing/interviews/resume-parser/update.resume.parser.types';
import { APIHeader, UserType } from '../../../../../../../utils/constants';
import { LoaderData } from '../../../../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../../../../utils/alert';
import Modal from '../../../../../../../utils/Modal/Modal';
import { getConfig } from '../../../../../../../utils/config/config';
import { ResumeLoadINData } from '../../../../../../../services/staffing/interviews/resume-load/update.resume.load.types';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;
const { AWS_CXUSER_BUCKET } = getConfig();

export type UploadedFile = {
  file: File;
  name: string;
  uId: string;
};

export const ResumeUploader: React.FC<ResumeLoadTypeProps> = ({
  jobOrg,
  jobId,
  tokenData,
  forClnt,
  jobNme,
}) => {
  const dispatch = useDispatch();
  const isMobileJob = window.innerWidth <= 468;
  const [loadingResumeParse, setLoadingResumeParse] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  // const [resumes, setResumes] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [resumeInfo, setResumeInfo] = useState<ResumeLoadINData[]>([]);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [, setUploadErrors] = useState<string[]>([]); // Track errors by filename or index
  // const [, setRetryIndex] = useState<number | null>(null);
  const [completedUploads, setCompletedUploads] = useState(0);
  // const totalResumes = resumeInfo.length;
  const uploadResumeS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const resumeParserResponse = useSelector((state: RootState) => state.campusXcel.resumeParser);
  const resumeLoadResponse = useSelector((state: RootState) => state.campusXcel.resumeLoad);
  const prevResumeInfoRef = React.useRef(resumeInfo);
  const [resumeParseData, setResumeParseData] = React.useState<ResumeParserAddDataInput>({
    jobCd: '',
    jobId: '',
    resume: '',
    uId: '',
  });
  const [, setIncomingResumeData] = React.useState<ResumeParserOPData>({
    JobCd: '',
    JobId: '',
    uId: '',
    fNme: '',
    lNme: '',
    eId: '',
    phn: '',
    dlCd: '',
    forClnt: '',
    jobNme: '',
  });

  // Handle Upload and S3 Deletion Failed object - START
  const validateResumes = (resumes: any) => resumes.every((resume: any) => {
    const validPhone = /^\d{10}$/.test(resume.phn);
    const validEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(resume.eId);
    const invalidFNameLName = /\d/.test(resume.fNme) || /\d/.test(resume.lNme);
    return validPhone && validEmail && !invalidFNameLName;
  });

  const handleUploadResumeFilesData = async () => {
    if (!validateResumes(resumeInfo)) {
      setErrorShowAlert(true);
      setAlertMsg('Please check the input data. Ensure valid names, emails, and phone numbers.');
      return;
    }
    setLoadingResumeParse(true);
    setUploadErrors([]);
    const formData = new FormData();
    const updatedResumeInfo = resumeInfo.map((resume) => {
      const originalFileName = resume.file?.name;
      const fileExtension = originalFileName?.split('.').pop();
      const uniqueFileName = `${resume.uId}.${fileExtension}`;
      if (resume.file) {
        const newFile = new File([resume.file], uniqueFileName, { type: resume.file.type });
        formData.append(uniqueFileName, newFile);
      }
      return {
        ...resume,
        fileName: uniqueFileName,
      };
    });
    const resumeDataWithoutFile = updatedResumeInfo.map((resume) => {
      const resumeCopy = { ...resume };
      delete resumeCopy.file;
      return resumeCopy;
    });

    dispatch(getResumeLoad({
      inputBody: { resumeData: resumeDataWithoutFile },
      requestType: APIHeader.REQ_LOAD_RESUME_DATA,
      uTp: UserType.RCRT,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (resumeLoadResponse.error && resumeLoadResponse.message !== '') {
      setLoadingResumeParse(false);
      setErrorShowAlert(true);
      setAlertMsg(resumeLoadResponse.message);
    }
  }, [resumeLoadResponse.error, resumeLoadResponse.message]);

  React.useEffect(() => {
    if (!resumeLoadResponse.error && resumeLoadResponse.message === 'executed') {
      setLoadingResumeParse(false);
      dispatch(clearResumeLoad());

      resumeLoadResponse.data.forEach((resumeResponse) => {
        if (resumeResponse.intSts === 'Interview Added Successfully') {
          const matchingResume = resumeInfo.find((resume) => resume.uId === resumeResponse.uId);

          if (matchingResume) {
            const uniqueFileName = resumeResponse.fileName;
            const { JobCd, JobId } = resumeResponse;

            const folderName = `cand-resm/${JobCd}/${JobId}`;

            const formData = new FormData();
            const originalFileName = matchingResume.file?.name;
            const fileExtension = originalFileName?.split('.').pop();
            const s3FileName = `${resumeResponse.uId}.${fileExtension}`;

            if (matchingResume.file) {
              const newFile = new File([matchingResume.file], s3FileName, { type: matchingResume.file.type });
              formData.append(s3FileName, newFile);
            }
            setIsUploading(true);
            // Dispatch the uploadS3FilesReq action with the necessary data
            dispatch(uploadS3FilesReq({
              bucketNme: AWS_CXUSER_BUCKET,
              folderName,
              fileNmeInit: uniqueFileName,
              formData,
              requestType: '',
              isEdit: false,
            }));
            setCompletedUploads((prevCount) => prevCount + 1);
          }
        } else if (resumeResponse.intSts === 'Interview Already Exists') {
          setCompletedUploads((prevCount) => prevCount + 1);
          const existingResume = resumeInfo.find((resume) => resume.uId === resumeResponse.uId);
          if (existingResume) {
            existingResume.uploadStatus = 'duplicate';
          }
        }
      });
    }
  }, [resumeLoadResponse.error, resumeLoadResponse.message]);

  React.useEffect(() => {
    if (uploadResumeS3AwsResponse.fileName) {
      setIsUploading(false);
      const resFileName = uploadResumeS3AwsResponse.fileName.split('/').pop() || '';

      const getBaseName = (fileName: string) => {
        const nameWithoutExt = fileName.split('.').slice(0, -1).join('.');
        return nameWithoutExt.replace(/_[^_]+$/, '');
      };

      const baseResFileName = getBaseName(resFileName);

      if (resumeInfo.length > 0) {
        const index = resumeInfo.findIndex((resume) => resume.uId === baseResFileName);

        if (index !== -1) {
          const updatedResumeInfo = [...resumeInfo];
          updatedResumeInfo[index] = {
            ...updatedResumeInfo[index],
            uploadStatus: 'success',
          };

          if (JSON.stringify(prevResumeInfoRef.current) !== JSON.stringify(updatedResumeInfo)) {
            setResumeInfo(updatedResumeInfo);
            prevResumeInfoRef.current = updatedResumeInfo;
            dispatch(clearUploadS3FilesReq());
          }
        }
      }
    }
  }, [uploadResumeS3AwsResponse.fileName, resumeInfo, dispatch]);

  // Handle Upload and S3 Deletion Failed object - END
  React.useEffect(() => {
    prevResumeInfoRef.current = resumeInfo;
  }, [resumeInfo]);

  const handleResumeSave = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;

    setResumeInfo((prevResumeInfo) => {
      const updatedResumes = [...prevResumeInfo];
      updatedResumes[index] = { ...updatedResumes[index], [name]: value };
      return updatedResumes;
    });
  };

  const toggleModal = (index: number | null) => {
    setModalIndex(index === modalIndex ? null : index);
  };

  const extractDetails = (text: string, uId: string) => {
    if (jobId !== '' && jobOrg !== '') {
      setLoadingResumeParse(true);
      const updatedResumeText = {
        ...resumeParseData,
        uId,
        jobId,
        jobCd: jobOrg,
        resume: text,

      };
      setResumeParseData(updatedResumeText);
      dispatch(getResumeParser({
        inputBody: updatedResumeText,
        requestType: APIHeader.REQ_PARSE_RESUME_DATA,
        uTp: UserType.RCRT,
        token: tokenData,
      }));
    }
  };

  const readDOCXFile = async (file: File, uId: string) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const arrayBuffer = event.target?.result as ArrayBuffer; // Type assertion
      if (arrayBuffer) {
        const result = await mammoth.extractRawText({ arrayBuffer });
        const text = result.value;
        extractDetails(text, uId);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const readPDFFile = async (file: File, uId: string) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      const typedArray = new Uint8Array(event.target?.result as ArrayBuffer);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;

      // Handle the content for each page
      const textPromises = Array.from({ length: pdf.numPages }, async (_, i) => {
        const page = await pdf.getPage(i + 1); // Pages are 1-indexed in pdf.js
        const content = await page.getTextContent();
        let pageText = '';

        content.items.forEach((item: any) => {
          pageText += `${item.str} `;
        });

        return pageText;
      });

      const allText = (await Promise.all(textPromises)).join(' ');
      extractDetails(allText, uId);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFilesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResumeInfo([]);
    const { files } = event.target;
    if (files) {
      const filesArray = Array.from(files);
      const totalFiles = uploadedFiles.length + filesArray.length;

      if (totalFiles > 10) {
        setErrorShowAlert(true);
        setAlertMsg('You can upload up to 10 resumes at a time.');
        return;
      }

      const uniqId = uuidv4();
      const newFiles = filesArray.map((file, index) => ({
        file,
        name: file.name,
        uId: `${uniqId}-${index}`,
      }));

      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);

      const filePromises = filesArray.map(async (file, index) => {
        const fileUId = `${uniqId}-${index}`;
        if (file.type === 'application/pdf') {
          await readPDFFile(file, fileUId);
          return { file, name: file.name, uId: fileUId };
        }
        if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          await readDOCXFile(file, fileUId);
          return { file, name: file.name, uId: fileUId };
        }
        return undefined;
      });

      Promise.all(filePromises)
        .then((fileInfos) => {
          const validFileInfos = fileInfos.filter(
            (info): info is { file: File; name: string; uId: string } => info !== undefined,
          );
          setUploadedFiles((prevFiles) => [...prevFiles, ...validFileInfos]);
        });
    }
  };

  React.useEffect(() => {
    if (resumeParserResponse.error && resumeParserResponse.message !== '') {
      setLoadingResumeParse(false);
      setErrorShowAlert(true);
      setAlertMsg(resumeParserResponse.message);
    }
  }, [resumeParserResponse.error, resumeParserResponse.message]);

  React.useEffect(() => {
    if (!resumeParserResponse.error && resumeParserResponse.message === 'executed') {
      setLoadingResumeParse(false);
      setIncomingResumeData(resumeParserResponse.data);
      const newResumeInfo: ResumeParserOPData = {
        uId: resumeParserResponse.data.uId,
        fNme: resumeParserResponse.data.fNme,
        lNme: resumeParserResponse.data.lNme,
        eId: resumeParserResponse.data.eId,
        phn: resumeParserResponse.data.phn,
        dlCd: resumeParserResponse.data.dlCd,
        JobCd: jobOrg,
        JobId: jobId,
        forClnt,
        jobNme,
      };

      const uploadedFile = uploadedFiles.find((file) => file.uId === resumeParserResponse.data.uId);

      if (uploadedFile) {
        setResumeInfo((prevInfo) => [
          ...prevInfo,
          {
            ...newResumeInfo,
            file: uploadedFile.file,
          },
        ]);
      } else {
        setResumeInfo((prevInfo) => [
          ...prevInfo,
          newResumeInfo,
        ]);
      }

      dispatch(clearResumeParser());
    }
  }, [resumeParserResponse.error, resumeParserResponse.message, uploadedFiles]);

  return (
    <div className="pt-4">
      { loadingResumeParse ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row>
        <Col xs="12" lg="12">
          <div className="upload-box align-center mx-2">
            <Input
              type="file"
              accept=".pdf,.docx"
              multiple
              onChange={handleFilesUpload}
              name="fileData"
              className="upload-box-input"
            />
            <span className="register-file-link">Choose Resume. Supported Files are - PDF/Docx</span>
          </div>
        </Col>
        <Col xs="12" lg="12">
          {resumeInfo.length > 0 && (
          <div className="pt-2">
            <span className="wrong-pass-key">Kindly review the data before submission, as the document analyzer may occasionally make errors.</span>
            {!isMobileJob
              ? (
                <div className="mx-1">
                  <table className="table">
                    <thead>
                      <tr>
                        <td><span className="align-start ml">Preview</span></td>
                        <td><span className="align-start ml">First Name</span></td>
                        <td><span className="align-start ml">Last Name</span></td>
                        <td><span className="align-start ml">Phone</span></td>
                        <td><span className="align-start ml">Email Id</span></td>
                        <td><span className="align-start ml">Status</span></td>
                      </tr>
                    </thead>
                    <tbody>
                      {resumeInfo.map((info, index) => (
                        <tr key={`${index.toString()}`}>
                          <td>
                            {info.file ? (
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleModal(index);
                                }}
                                className="button-icon"
                              >
                                <FontAwesomeIcon icon={faExpand} />
                              </Button>
                            ) : (
                              <div>No file uploaded</div>
                            )}
                          </td>
                          <td>
                            <Input
                              placeholder="Enter First Name"
                              type="text"
                              name="fNme"
                              value={info.fNme}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Last Name"
                              type="text"
                              name="lNme"
                              value={info.lNme}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Mobile Number"
                              type="text"
                              name="phn"
                              value={info.phn}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </td>
                          <td>
                            <Input
                              placeholder="Enter Email Id"
                              type="text"
                              name="eId"
                              value={info.eId}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </td>
                          <td>
                            {info.uploadStatus === 'success' && <span className="text-success">Uploaded</span>}
                            {info.uploadStatus === 'failed' && (
                            <>
                              <span className="text-danger">Failed</span>
                              {/* <Button
                                onClick={() => handleRetryUpload(index)}
                                className="ml-2"
                              >
                                Retry
                              </Button> */}
                            </>
                            )}
                            {info.uploadStatus === 'uploading' && <span className="text-info">Uploading...</span>}
                            {!info.uploadStatus && <span className="text-muted">Pending</span>}
                            {info.uploadStatus === 'duplicate' && <span className="text-danger">Duplicate</span>}
                          </td>
                          {modalIndex === index && (
                            <Modal
                              show={modalIndex === index}
                              onClose={() => toggleModal(null)}
                              disCancel={false}
                              targetId={modalIndex.toString()}
                              title={(
                                <div className="mx-1 my-2">
                                  <span className="input-header-text pt-2 ml">{info.fNme} {info.lNme}</span>
                                </div>
                              )}
                              size="XL"
                            >
                              {info.file && info.file.type === 'application/pdf' ? (
                                <iframe
                                  src={URL.createObjectURL(info.file)}
                                  width="100%"
                                  height="700px"
                                  title="PDF Preview"
                                />
                              ) : info.file && info.file.type
                                === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                  <div>
                                    <div className="align-center pt-2">
                                      <p>Word documents cannot be previewed here.</p>
                                    </div>
                                    <div className="align-center mb-3 pt-2">
                                      <a
                                        href={URL.createObjectURL(info.file)}
                                        download={info.file.name}
                                        className="button-icon"
                                      >
                                        Download Word Document
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <p>Unsupported file type</p>
                                )}
                            </Modal>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null }
            {isMobileJob
              ? (
                <Row className="mx-1">
                  {resumeInfo.map((info, index) => (
                    <Col key={`${index.toString()}`} lg="6" xs="12">
                      <Row className="chip-box-jobs-pvt my-3">
                        <Col xs="12">
                          <div className="align-center">
                            <span className="text-lite-sub">{info.fNme} {info.lNme}</span>
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="pt-3">
                            <h6 className="text-lite align-start ml-1">First Name</h6>
                            <Input
                              placeholder="Enter First Name"
                              type="text"
                              name="fNme"
                              value={info.fNme}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="pt-3">
                            <h6 className="text-lite align-start ml-1">Last Name</h6>
                            <Input
                              placeholder="Enter Last Name"
                              type="text"
                              name="lNme"
                              value={info.lNme}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="pt-3">
                            <h6 className="text-lite align-start ml-1">Mobile Number</h6>
                            <Input
                              placeholder="Enter Mobile Number"
                              type="text"
                              name="phn"
                              value={info.phn}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </div>
                        </Col>
                        <Col xs="12" lg="6">
                          <div className="pt-3">
                            <h6 className="text-lite align-start ml-1">Email Id</h6>
                            <Input
                              placeholder="Enter Email Id"
                              type="text"
                              name="eId"
                              value={info.eId}
                              onChange={(e) => handleResumeSave(e, index)}
                              className="campus-input"
                            />
                          </div>
                        </Col>
                        <div className="pt-2" />
                      </Row>
                    </Col>
                  ))}
                </Row>
              ) : null}
            <div className="my-2 align-center pt-2">
              <Button
                className="campus-button-all"
                onClick={handleUploadResumeFilesData}
                disabled={isUploading || !validateResumes(resumeInfo) || completedUploads === resumeInfo.length}
              >
                UPLOAD RESUME
              </Button>
            </div>
          </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResumeUploader;
