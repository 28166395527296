import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select, { MultiValue } from 'react-select';
import Creatable from 'react-select/creatable';
import '../../../../recruiter.scss';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { UpdateJobPhaseTypeProps } from '../../../../recruiter.type';
import {
  clearListMETAReq,
  editJobsReq,
  listMETAReq,
} from '../../../../../../../../store/campusXcel/actions';
import {
  APIHeader, ListJobSchedule, ListJobType, ListVacancies, RecruitmentTimeline, UserType,
} from '../../../../../../../../utils/constants';
import { AllMETAResult } from '../../../../../../../../services/common/metadata-db/list-meta.types';

export const UpdateJobPhase2: React.FC<UpdateJobPhaseTypeProps> = ({
  // jobPhase,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  setAddJobRes,
  addJobRes,
  updatePh2Job,
  setUpdatePh2Job,
  // setJobPhStatus,
  apiReqTp,
  setApiReqTp,
}) => {
  const dispatch = useDispatch();
  const [loadingMeta, setLoadingMeta] = React.useState(false);
  const [whichAPI, setWhichAPI] = React.useState('');
  // const [loadingDesigMeta, setLoadingDesigMeta] = React.useState(false);
  const updateJobPhase2Response = useSelector((state: RootState) => state.campusXcel.updateJob);
  const [, setMultiJobTp] = React.useState<MultiValue<{ label: string; value: string; }>>([]);
  // const addNewMetaResponse = useSelector((state: RootState) => state.campusXcel.updateS3CxMeta);
  const getMetaInfoResponse = useSelector((state: RootState) => state.campusXcel.listMetaData);
  const [skillListData, setSkillListData] = React.useState<AllMETAResult[]>([]);
  const [oSkillListData, setOSkillListData] = React.useState<AllMETAResult[]>([]);
  const [designationListData, setDesignationListData] = React.useState<AllMETAResult[]>([]);

  // const handleBackToPhase1 = () => {
  //   setApiReqTp('EDIT-BACK-REQ-TO-PH1');
  //   // setJobPhStatus(1);
  // };
  const handleChangeUpdateJob = (event: any) => {
    setUpdatePh2Job({ ...updatePh2Job, [event.target.name]: event.target.value });
  };

  const handleChangeIsFemale = (event: any) => {
    if (event.target.checked) {
      setUpdatePh2Job({ ...updatePh2Job, isFemale: 'YES' });
    } else {
      setUpdatePh2Job({ ...updatePh2Job, isFemale: 'NO' });
    }
  };

  const handleChangeIsAbled = (event: any) => {
    if (event.target.checked) {
      setUpdatePh2Job({ ...updatePh2Job, isAbled: 'YES' });
    } else {
      setUpdatePh2Job({ ...updatePh2Job, isAbled: 'NO' });
    }
  };

  const handleCheckPlndDt = (status: boolean) => {
    setUpdatePh2Job({ ...updatePh2Job, isPlndDt: status });
  };

  const handleMyCertDate = (e:any) => {
    const dateData = moment(e.target.value).format('YYYY-MM-DD');
    setUpdatePh2Job({ ...updatePh2Job, [e.target.name]: dateData });
  };

  // React.useEffect(() => {
  //   if (jobPhase === 'phase-2' && addJobRes.JobCd !== '' && addJobRes.JobId !== '') {
  //     setUpdatePh2Job({ ...updatePh2Job, JobCd: addJobRes.JobCd, JobId: addJobRes.JobId });
  //   }
  // }, [jobPhase, addJobRes.JobCd, addJobRes.JobId]);

  const handleUpdatePhase2Job = (e: any) => {
    e.preventDefault();
    if (updatePh2Job.JobCd !== '' || updatePh2Job.JobId !== '') {
      if (apiReqTp === 'NEW-ADD-REQ-TO-PH2') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh2Job,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE2',
        }));
      }
      if (apiReqTp === 'EDIT-BACK-REQ-TO-PH2' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH2' || apiReqTp === 'PSTG2-req') {
        setLoadingJob(true);
        dispatch(editJobsReq({
          inputBody: updatePh2Job,
          requestType: APIHeader.REQ_UPDATE_JOB_BY_PHASE,
          uTp: UserType.RCRT,
          token: tokenData,
          fldUpdtTp: 'PHASE2',
        }));
      }
    }
  };

  React.useEffect(() => {
    if (updateJobPhase2Response.error && updateJobPhase2Response.message !== '') {
      setLoadingJob(false);
      setAlertMsg(updateJobPhase2Response.message);
      setErrorShowAlert(true);
    }
  }, [updateJobPhase2Response.error, updateJobPhase2Response.message]);

  React.useEffect(() => {
    if (!updateJobPhase2Response.error && updateJobPhase2Response.data.JobCd !== '' && updateJobPhase2Response.data.JobId !== '' && apiReqTp === 'NEW-ADD-REQ-TO-PH2') {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase2Response.data.JobCd,
        JobId: updateJobPhase2Response.data.JobId,
        NxtStg: updateJobPhase2Response.data.NxtStg,
      });
      setApiReqTp('NEW-ADD-REQ-TO-PH3');
    }
  }, [updateJobPhase2Response.error, updateJobPhase2Response.data.JobCd, updateJobPhase2Response.data.JobId]);

  React.useEffect(() => {
    if (!updateJobPhase2Response.error && updateJobPhase2Response.data.JobCd !== '' && updateJobPhase2Response.data.JobId !== '' && (apiReqTp === 'EDIT-BACK-REQ-TO-PH2' || apiReqTp === 'EDIT-FORWARD-REQ-TO-PH2' || apiReqTp === 'PSTG2-req')) {
      setLoadingJob(false);
      setAddJobRes({
        ...addJobRes,
        JobCd: updateJobPhase2Response.data.JobCd,
        JobId: updateJobPhase2Response.data.JobId,
        NxtStg: updateJobPhase2Response.data.NxtStg,
      });
      setApiReqTp('EDIT-FORWARD-REQ-TO-PH3');
    }
  }, [updateJobPhase2Response.error, updateJobPhase2Response.data.JobCd, updateJobPhase2Response.data.JobId]);

  const scheduleNameoptions = ListJobSchedule.map((schedule) => ({
    label: schedule.label,
    value: schedule.value,
  }));

  const selectedScheduleNameOptions = scheduleNameoptions && scheduleNameoptions.find(
    (option) => (option.value === updatePh2Job.schdl),
  );

  const vacancyNameoptions = ListVacancies.map((vacancy) => ({
    value: vacancy.value,
    label: vacancy.label,
  }));

  const selectedVacancyNameOptions = vacancyNameoptions && vacancyNameoptions.find(
    (option) => (option.value === updatePh2Job.vacncy),
  );

  const jobTpNameoptions = ListJobType.map((jobtp) => ({
    label: jobtp.label,
    value: jobtp.value,
  }));

  const handleAllJobType = () => updatePh2Job.jobTp.map((item) => {
    const foundOption = jobTpNameoptions.find((option) => option.value === item);
    return foundOption ? { label: foundOption.label, value: foundOption.value } : { label: item, value: item };
  });

  const recruitTLNameoptions = RecruitmentTimeline.map((rcrtTL) => ({
    label: rcrtTL.label,
    value: rcrtTL.value,
  }));

  const selectedrecruitTLNameoptions = recruitTLNameoptions && recruitTLNameoptions.find(
    (option) => (option.value === updatePh2Job.timelne),
  );

  const handleSelectJobType = (e: MultiValue<{ label: string; value: string; }>) => {
    if (e && e.length) {
      const jobTypesV = e.map(({ value }) => value); // Extract the values (for storage)
      setUpdatePh2Job({ ...updatePh2Job, jobTp: jobTypesV }); // Store the values in jobTp
      setMultiJobTp(e); // Store the entire object (label and value) for Select display
    } else {
      setUpdatePh2Job({ ...updatePh2Job, jobTp: [] });
      setMultiJobTp([]);
    }
  };

  const handleSelectScheduleData = (selectedOption: any) => {
    if (selectedOption) {
      setUpdatePh2Job({
        ...updatePh2Job,
        schdl: selectedOption.value,
      });
    }
  };

  const handleSelectRecruitTimeline = (selectedOption: any) => {
    if (selectedOption) {
      setUpdatePh2Job({
        ...updatePh2Job,
        timelne: selectedOption.value,
      });
    }
  };

  const handleSelectVacancyData = (selectedOption: any) => {
    if (selectedOption) {
      setUpdatePh2Job({
        ...updatePh2Job,
        vacncy: selectedOption.value,
      });
    }
  };
  // Skills and Designation - Start
  const handleGetDesignationChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('DESG');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        search: event,
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'DESG',
        token: tokenData,
      }));
    }
  };

  const desigNameoptions = designationListData.map((desg) => ({
    label: `${desg.nme}`,
    value: `${desg.nme}`,
  }));

  const selectedDesgNameOptions = desigNameoptions && desigNameoptions.find(
    (option) => (option.value === `${updatePh2Job.desg}`),
  );

  const handleGetSkillChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('SKILLS');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        search: event,
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'SKILL',
        token: tokenData,
      }));
    }
  };

  const handleGetOSkillChange = (event: any) => {
    if (event.length > 2) {
      setWhichAPI('OSKILLS');
      setLoadingMeta(true);
      dispatch(listMETAReq({
        search: event,
        limit: 30,
        requestType: APIHeader.REQ_ALL_METADATA,
        uTp: UserType.RCRT,
        metaTp: 'SKILL',
        token: tokenData,
      }));
    }
  };

  React.useEffect(() => {
    if (!getMetaInfoResponse.isError && getMetaInfoResponse.message === 'executed') {
      setLoadingMeta(false);
      if (whichAPI === 'SKILLS') {
        setSkillListData((prevData) => {
          const existingNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewData = getMetaInfoResponse.data.filter((record) => !existingNames.has(record.nme));
          return [...prevData, ...uniqueNewData];
        });
      } if (whichAPI === 'OSKILLS') {
        setOSkillListData((prevData) => {
          const existingOSkillNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewOSkillData = getMetaInfoResponse.data.filter((record) => !existingOSkillNames.has(record.nme));
          return [...prevData, ...uniqueNewOSkillData];
        });
      } if (whichAPI === 'DESG') {
        setDesignationListData((prevData) => {
          const existingNames = new Set(prevData.map((record) => record.nme));
          const uniqueNewData = getMetaInfoResponse.data.filter((record) => !existingNames.has(record.nme));
          return [...prevData, ...uniqueNewData];
        });
      }
      dispatch(clearListMETAReq());
    }
  }, [getMetaInfoResponse.isError, getMetaInfoResponse.message]);

  // React.useEffect(() => {
  //   if (getMetaInfoResponse.success) {
  //     // setLoadingSkillMeta(false);

  //     // Assuming `updatePh2Job.skills` is an array of strings
  //     const existingNames = new Set(updatePh2Job.skills);

  //     // Filter the new data to include only unique names
  //     const uniqueNewData = getMetaInfoResponse.data
  //       .filter((record) => !existingNames.has(record.nme))
  //       .map((record) => record.nme); // Map to just the name strings

  //     // Update `skills` with the unique names
  //     setUpdatePh2Job({
  //       ...updatePh2Job,
  //       skills: [...updatePh2Job.skills, ...uniqueNewData], // Add the new unique skills
  //     });

  //     // Clear the request
  //     dispatch(clearGetS3CxMetaReq());
  //   }
  // }, [getMetaInfoResponse.success]);

  // const handleUpdateMetaData = (e: any) => {
  //   e.preventDefault();
  //   setLoaderClient(true);
  //   dispatch(updateS3CxMetaReq({
  //     bucketNme: AWS_CXADMIN_BUCKET,
  //     fileName: 'recruiter-org.json',
  //     folderName: 'cx-meta-data',
  //     inputBody: newStaffClient,
  //     requestType: '',
  //   }));
  // };
  // React.useEffect(() => {
  //   if (staffClientAddMetaResponse.message !== '') {
  //     setLoaderClient(false);
  //     if (staffClientAddMetaResponse.success) {
  //       setModalAddStaffClient(false);
  //       setAlertMsg(staffClientAddMetaResponse.message);
  //       setSuccessShowAlert(true);
  //       setNewStaffClient({
  //         nme: '',
  //         cty: '',
  //         st: '',
  //       });
  //     } else {
  //       setAlertMsg(staffClientAddMetaResponse.message);
  //       setErrorShowAlert(true);
  //     }
  //     dispatch(clearUpdateS3CxMetaReq());
  //   }
  // }, [staffClientAddMetaResponse.message]);

  const skillNameoptions = skillListData.map((skill) => ({
    label: `${skill.nme}`,
    value: `${skill.nme}`,
  }));
  // const selectedSkillNameOptions = skillNameoptions && skillNameoptions.find(
  //   (option) => (option.value === `${updatePh2Job.nme}|${staffClient.citySt}`),
  // );

  // const skillNameoptions = ListHobbies.map((skill) => ({
  //   label: skill.label,
  //   value: skill.value,
  // }));

  const handleSelectDesgData = (e: any) => {
    if (e && e.value) { // Check if e is not null and has a value property
      setUpdatePh2Job({ ...updatePh2Job, desg: e.value });
    } else {
      setUpdatePh2Job({ ...updatePh2Job, desg: '' });
    }
  };

  const handleSelectSkillData = (e: MultiValue<{ label: string; value: string; }> | null) => {
    let updatedSkill: string[] = [];
    if (e && e.length) {
      updatedSkill = e.map(({ label }) => label);
    }
    setUpdatePh2Job({ ...updatePh2Job, skills: updatedSkill });
  };

  const skillsList = updatePh2Job.skills.map((item) => ({ label: item, value: item }));

  const oSkillNameoptions = oSkillListData.map((oSkill) => ({
    label: `${oSkill.nme}`,
    value: `${oSkill.nme}`,
  }));

  const handleSelectOSkillData = (e: MultiValue<{ label: string; value: string; }> | null) => {
    let updatedOSkill: string[] = [];
    if (e && e.length) {
      updatedOSkill = e.map(({ label }) => label);
    }
    setUpdatePh2Job({ ...updatePh2Job, oSkills: updatedOSkill });
  };

  const oSkillsList = updatePh2Job.oSkills.map((item) => ({ label: item, value: item }));
  // Skills and Designation - End

  return (
    <div className="my-1">
      <Form className="job-add-card" onSubmit={handleUpdatePhase2Job}>
        <div className="my-2 mb-3 align-center">
          <span className="text-lite-grey-sub">Provide Job Info</span>
        </div>
        <Row className="align-center">
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Job Designation</span>
              <Creatable
                key="designation"
                value={updatePh2Job.desg === '' ? null : selectedDesgNameOptions}
                options={desigNameoptions}
                isClearable
                onInputChange={handleGetDesignationChange}
                onChange={handleSelectDesgData}
                styles={{
                  menu: (base) => ({
                    ...base,
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loadingMeta}
                noOptionsMessage={() => 'No Designation Found'}
                placeholder="Enter or Add New Designation"
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Desired Skills<span className="mandate-star">*</span></span>
              <Creatable
                key="skills"
                options={skillNameoptions}
                isClearable
                onInputChange={handleGetSkillChange}
                onChange={(e) => handleSelectSkillData(e)}
                value={skillsList}
                isMulti
                styles={{
                  multiValue: (provided: any) => ({
                    ...provided,
                    background: '#f6eeeb',
                    padding: '1px',
                  }),
                  menu: (base) => ({
                    ...base,
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loadingMeta}
                noOptionsMessage={() => 'No Skills Found'}
                placeholder="Enter or Add New Skills"
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Good to have Skills</span>
              <Creatable
                key="oSkills"
                options={oSkillNameoptions}
                isClearable
                onInputChange={handleGetOSkillChange}
                onChange={(e) => handleSelectOSkillData(e)}
                value={oSkillsList}
                isMulti
                styles={{
                  multiValue: (provided: any) => ({
                    ...provided,
                    background: '#f6eeeb',
                    padding: '1px',
                  }),
                  menu: (base) => ({
                    ...base,
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                isLoading={loadingMeta}
                noOptionsMessage={() => 'No Skills Found'}
                placeholder="Enter or Add New Skills"
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Select Job Type<span className="mandate-star">*</span></span>
              <Select
                options={jobTpNameoptions}
                onChange={handleSelectJobType}
                value={handleAllJobType()}
                required
                isMulti
                styles={{
                  multiValue: (provided: any) => ({
                    ...provided,
                    background: '#f6eeeb',
                    padding: '1px',
                  }),
                  menu: (base) => ({
                    ...base,
                    position: 'relative',
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#F6B26B' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: '10px',
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Job Type Found'}
                placeholder="Enter Job Type"
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Job Schedule<span className="mandate-star">*</span></span>
              <Select
                value={updatePh2Job.schdl === '' ? null : selectedScheduleNameOptions}
                options={scheduleNameoptions}
                onChange={handleSelectScheduleData}
                required
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Job Schedule Found'}
                placeholder="Select Job Schedule..."
              />
            </div>
            {updatePh2Job.schdl === 'OTH'
              ? (
                <div className="pt-4 mx-1">
                  <span className="text-lite ml-1 align-start">Create New Schedule</span>
                  <Input
                    placeholder="Enter New Schedule"
                    type="text"
                    name="oSchdl"
                    value={updatePh2Job.oSchdl}
                    onChange={handleChangeUpdateJob}
                    className="campus-input"
                  />
                </div>
              ) : null}
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 ml-1 mx-3">
              <span className="text-lite align-start">Is there any Planned Start Date for this job ?</span>
              <Row className="pt-2 collapse-bg mb-2">
                <Col lg="3" xs="3">
                  <div className={updatePh2Job.isPlndDt ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                    <Input
                      type="radio"
                      checked={updatePh2Job.isPlndDt === true}
                      className="campus-radio-box cursor-pointer"
                      onChange={() => handleCheckPlndDt(true)}
                    />
                    <Label check className="form-check-text ml">Yes</Label>
                  </div>
                </Col>
                <Col lg="3" xs="3">
                  <div className={updatePh2Job.isPlndDt ? 'align-start my-1 pt-3' : 'align-start my-1'}>
                    <Input
                      type="radio"
                      checked={updatePh2Job.isPlndDt === false}
                      className="campus-radio-box cursor-pointer"
                      onChange={() => handleCheckPlndDt(false)}
                    />
                    <Label check className="form-check-text ml">No</Label>
                  </div>
                </Col>
                {updatePh2Job.isPlndDt
                  ? (
                    <Col lg="6" xs="6">
                      <div className="align-center my-1">
                        <Input
                          type="date"
                          name="plndDt"
                          required={updatePh2Job.isPlndDt}
                          min={new Date().toISOString().split('T')[0]}
                          value={updatePh2Job.plndDt}
                          onChange={(event) => handleMyCertDate(event)}
                          className="campus-input"
                        />
                      </div>
                    </Col>
                  ) : null}
              </Row>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1 align-start">
              <span className="table-text mr-2 align-start pt-2">Number of Open Position for this Job</span>
              <Select
                value={selectedVacancyNameOptions}
                options={vacancyNameoptions}
                onChange={handleSelectVacancyData}
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '40px',
                    borderRadius: '5px',
                    padding: 1,
                    width: '130px',
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1">
              <span className="text-lite ml-1 align-start">Recruitment Timeline<span className="mandate-star">*</span></span>
              <Select
                value={updatePh2Job.timelne === '' ? null : selectedrecruitTLNameoptions}
                options={recruitTLNameoptions}
                onChange={handleSelectRecruitTimeline}
                required
                styles={{
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#8B0000' },
                    border: '1px solid #DDE0E4',
                    minHeight: '50px',
                    borderRadius: '10px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: '#575656',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No Recruitment Timeline Found'}
                placeholder="Select Recruitment Timeline..."
              />
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1 align-start">
              <Label check>
                <Input
                  type="checkbox"
                  name="isFemale"
                  value={updatePh2Job.isFemale}
                  onClick={handleChangeIsFemale}
                  className="campus-check-box ml pt-2 cursor-pointer"
                />{' '}
                <span className="text-lite-grey">Is this Job only for Female Candidates?</span>
              </Label>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-4 mx-1 align-start">
              <Label check>
                <Input
                  type="checkbox"
                  name="isAbled"
                  value={updatePh2Job.isAbled}
                  onChange={handleChangeIsAbled}
                  className="campus-check-box ml pt-2 cursor-pointer"
                />{' '}
                <span className="text-lite-grey">Is this Job only for Differently Abled Candidates?</span>
              </Label>
            </div>
          </Col>
          <Col xs="12" lg="10">
            <div className="pt-3" />
            <hr className="hr-dasshed-lite" />
          </Col>
          <Col xs="6" lg="5">
            {/* <div className="align-start mx-2">
              <Button className="button-transparent-jobs mr-2" onClick={handleBackToPhase1}>
                <FontAwesomeIcon icon={faArrowLeft} className="mr" />
                Back
              </Button>
            </div> */}
          </Col>
          <Col xs="6" lg="5">
            <div className="align-end mx-2">
              <Button className="button-transparent">
                Continue
                <FontAwesomeIcon icon={faArrowRight} className="ml" />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="pt-4" />
    </div>
  );
};

export default UpdateJobPhase2;
