import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Row, Col, Card, CardImg, Nav, NavItem, TabContent, TabPane,
  NavLink,
} from 'reactstrap';
import ResumeUploader from './read.resumes';
import { getTokenFromLocalStorage } from '../../../../../../../utils/service/localstorage-service';
import JobInterviewComponent from './inerview-by-job/jobs.interview.component';

const JobResumeComponent: React.FC = () => {
  const tokenData = getTokenFromLocalStorage();
  const { jobOrg, jobId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState('interview');

  const jobName = params.get('nme');
  const forClnt = params.get('forClnt');

  return (
    <div className="pt-2">
      <div className="allign-start ml">
        <CardImg className="cx-main-header-img-card" src="/images/icons/job-info.png" alt="job" />
        <span className="text-lite-grey ml">{jobId} | {jobName}</span>
      </div>
      <Row className="onboard-main-margin-top pt-3">
        <Col xs="12">
          <Nav tabs className="campus-onboard-tab">
            <div className="pt-1">
              <div className="campus-column-onboard-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'interview'} onClick={() => setActiveTab('interview')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/cv-act.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Activity</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
              <div className="campus-column-onboard-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'upload-resume'} onClick={() => setActiveTab('upload-resume')}>
                    <div className="align-center">
                      <CardImg src="/images/icons/resume-pdf.png" className="campus-tab-onboard-icon-img" alt="Icon 3" />
                    </div>
                    <div className="align-center">
                      <span className="campus-tab-text mx-1">Load CV</span>
                    </div>
                  </NavLink>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="interview">
              <JobInterviewComponent
                intvStatus="interview"
                interviewCd={jobId || ''}
              />
            </TabPane>
            <TabPane tabId="upload-resume">
              <div className="pt-4">
                <Row className="align-center">
                  <Col xs="12" lg="11">
                    <Card className="cx-jobs-view-card">
                      <div>
                        <span className="chip-box-jobs-pvt">{jobName}</span>
                      </div>
                      <div>
                        <ResumeUploader
                          jobOrg={jobOrg || ''}
                          jobId={jobId || ''}
                          forClnt={forClnt || ''}
                          tokenData={tokenData}
                          jobNme={jobName || ''}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Col>
        <div className="pt-5" />
      </Row>
      <div className="mb-3 p-4" />
    </div>
  );
};
export default JobResumeComponent;
