import React from 'react';
import {
  Button,
  Card, Col, Input, Row,
} from 'reactstrap';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import { ResumeInputHandleProps } from '../template/resume.template.type';
import {
  clearUploadS3FilesReq, clearXclnResumeFilePutReq, uploadS3FilesReq, xclnResumeFilePutReq,
} from '../../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { getConfig } from '../../../../../utils/config/config';
import { XclnJdFile } from '../../../../../services/staffing/resume-preview/add-resume-file/update.resume.file.types';

Chart.register(ArcElement, Tooltip, Legend);

const { AWS_CXUSER_BUCKET } = getConfig();

export const XcelianResumeInput: React.FC<ResumeInputHandleProps> = ({
  handleUpdateResumeData, previewResume, tokenData, myProfile,
}) => {
  const dispatch = useDispatch();
  const [loadingResumeParse, setLoadingResumeParse] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const uploadResumeS3AwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const xcelianResumeFileStatusResponse = useSelector((state: RootState) => state.campusXcel.xcelianResumeFileUpdate);
  const [dataArrayAccountDough] = React.useState<number[]>([35, 65]); // setDataArrayAccountDough
  const [resumeData, setResumeData] = React.useState<XclnJdFile>({
    resumeFile: '',
    file: undefined,
  });

  const dataAccountDough = {
    labels: ['Pending', 'Completed'], // These will be hidden on the chart
    datasets: [
      {
        data: dataArrayAccountDough,
        backgroundColor: ['#f8d6d6', '#038e08'],
        hoverBackgroundColor: ['#f8d6d6', '#038e08'],
      },
    ],
  };

  const optionsAccountDough = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        font: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: () => '',
          label: (context: any) => {
            const value = context.parsed || 0;
            return `${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')} %`; // Show only value in tooltip
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };
  const handleUploadResumeFilesData = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      const { files } = e.target;
      if (!files || files.length === 0) return;

      const file = files[0]; // Get the first file
      const originalFileName = file.name;
      const fileExtension = originalFileName?.split('.').pop();
      const uniqueFileName = `${myProfile.UserTp}-${myProfile.UserCd}.${fileExtension}`;
      setResumeData({ ...resumeData, resumeFile: uniqueFileName, file });

      const resumeCopy = { ...resumeData, resumeFile: uniqueFileName };
      setLoadingResumeParse(true);
      dispatch(xclnResumeFilePutReq({
        inputBody: resumeCopy,
        requestType: APIHeader.REQ_UPDATE_XCELIAN_RESUME_FILE,
        uTp: UserType.XCLN,
        token: tokenData,
      }));
    }
  };

  // Handle getResumeLoad API success/error
  React.useEffect(() => {
    if (xcelianResumeFileStatusResponse.error && xcelianResumeFileStatusResponse.message !== '') {
      setLoadingResumeParse(false);
      dispatch(clearXclnResumeFilePutReq());
      setErrorShowAlert(true);
      setAlertMsg(xcelianResumeFileStatusResponse.message);
    } else if (!xcelianResumeFileStatusResponse.error && xcelianResumeFileStatusResponse.message === 'executed' && myProfile.UserTp !== '' && myProfile.UserCd !== '') {
      dispatch(clearXclnResumeFilePutReq());
      // const { file } = resumeData.file;
      const uniqueFileName = xcelianResumeFileStatusResponse.jdFileName;
      if (resumeData.file) {
        const formData = new FormData();
        formData.append(uniqueFileName, resumeData.file);
        dispatch(uploadS3FilesReq({
          bucketNme: AWS_CXUSER_BUCKET,
          folderName: `cand-resm/${myProfile.UserTp}/${myProfile.UserCd}`,
          fileNmeInit: uniqueFileName,
          formData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setErrorShowAlert(true);
        setAlertMsg('File is undefined, cannot upload.');
      }
    }
  }, [xcelianResumeFileStatusResponse, resumeData, dispatch, myProfile]);

  // Handle AWS S3 file upload success
  React.useEffect(() => {
    if (uploadResumeS3AwsResponse.fileName) {
      setLoadingResumeParse(false);
      setSuccessShowAlert(true);
      setAlertMsg('File uploaded successfully');
      dispatch(clearUploadS3FilesReq());
    }
  }, [uploadResumeS3AwsResponse.fileName, dispatch]);

  return (
    <div>
      { loadingResumeParse ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="my-1">
        <Col xs="12">
          <div>
            <Card className="xcelian-card">
              <Row>
                <Col xs="6" lg="8">
                  <div className="pt-2 ml">
                    <span className="text-lite-grey-sub">Current Resume status</span>
                  </div>
                  <div className="ml">
                    <span className="text-lite-grey">Completed: 65%</span>
                  </div>
                  <div className="align-center pt-3">
                    <div className="xcelian-upload-box align-center mx-2">
                      <Input
                        type="file"
                        accept=".pdf, .docx"
                        name="fileData"
                        className="xcelian-upload-box-input"
                        onChange={(e) => handleUploadResumeFilesData(e)}
                      />
                      <span className="register-file-link">Upload Your Resume</span>
                    </div>
                    {/* <Input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }} // Hide the file input
                      onChange={(e) => handleUploadResumeFilesData(e, 'candidateType', 'candidateId')}
                    />
                    <Button className="job-button-approve" onClick={(e) => handleUploadResumeFilesData()}>
                      Upload Your Resume
                    </Button> */}
                  </div>
                  <div className="align-center">
                    <span className="dashboard-sub-post-text-lite">Upload your resume directly</span>
                  </div>
                </Col>
                <Col xs="6" lg="4">
                  <div className="my-2 mr">
                    <Doughnut data={dataAccountDough} options={optionsAccountDough} />
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-5">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Personal Information</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="11">
                  <div className="pt-2 mx-1 align-center">
                    <span className="text-lite-grey">Comming Soon</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="11 align-end pt-3">
                  <Button className="job-button-approve" disabled>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-3">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Organization Info</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="11">
                  <div className="pt-2 mx-1 align-center">
                    <span className="text-lite-grey">Comming Soon</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="11 align-end pt-3">
                  <Button className="job-button-approve" disabled>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-3">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Projects</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="11">
                  <div className="pt-2 mx-1">
                    <span className="text-lite-grey align-start ml">Enter your Designation</span>
                    <Input
                      disabled
                      placeholder="Designation"
                      type="text"
                      name="desg"
                      value={previewResume.desg}
                      onChange={(e) => handleUpdateResumeData(e)}
                      className="xcelian-input"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="11 align-end pt-3">
                  <Button className="job-button-approve" disabled>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-3">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Skills</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="11">
                  <div className="pt-2 mx-1 align-center">
                    <span className="text-lite-grey">Comming Soon</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="11 align-end pt-3">
                  <Button className="job-button-approve" disabled>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <Col xs="12">
          <div className="pt-3">
            <Card className="xcelian-card-form">
              <div className="pt-1 align-center">
                <span className="text-lite-grey-sub">Education</span>
              </div>
              <Row className="align-center pt-2">
                <Col xs="12" lg="11">
                  <div className="pt-2 mx-1 align-center">
                    <span className="text-lite-grey">Comming Soon</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" lg="11 align-end pt-3">
                  <Button className="job-button-approve" disabled>
                    SAVE
                  </Button>
                </Col>
              </Row>
              <div className="pt-2" />
            </Card>
          </div>
        </Col>
        <div className="pt-4" />
      </Row>
    </div>
  );
};

export default XcelianResumeInput;
