import React from 'react';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import './template1.scss';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DownloadTemplate1 from './download';
import { PreviewResumeProps } from '../resume.template.type';

export const PreviewTemplate1: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => (
  <div>
    <Row>
      <Col xs="12">
        <div className="float-right-template-1">
          <DownloadTemplate1 previewResume={previewResume} myProfile={myProfile} />
        </div>
      </Col>
    </Row>
    <Card className="template-card-design">
      <Row className="mx-1">
        <Col xs="12">
          <span className="template-header">{myProfile.fNme}{' '}{myProfile.lNme}</span>
        </Col>
        <Col xs="12 ml pt-1">
          <FontAwesomeIcon icon={faEnvelope} className="menu-icon-cc" />
          <span className="template-desc-text ml">{myProfile.cEId}</span>
        </Col>
        <Col xs="12 ml">
          <FontAwesomeIcon icon={faPhone} className="menu-icon-cc" />
          <span className="template-desc-text ml">{myProfile.dlCd} {myProfile.cPh}</span>
        </Col>
        <Col xs="12">
          <span className="template-header">{previewResume.desg}</span>
        </Col>
      </Row>
    </Card>
  </div>
);
export default PreviewTemplate1;
