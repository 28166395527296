import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col, Row,
} from 'reactstrap';
import './news.scss';
import { ListNewsletterResultData } from '../../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import { formatUserDate } from '../../../../utils/constants';

  interface TableProps {
    clickedIndex: string;
    data: ListNewsletterResultData[];
    handleGetNewsDetails: (UrlHtml: string, htmlTitleDetail: string, MediaId: string) => void;
  }

const AllNewsletter: React.FC<TableProps> = ({
  data,
  clickedIndex,
  handleGetNewsDetails,
}) => (
  <div className="">
    {data.length > 0
      ? (
        <div>
          <Row className="align-start margin-jobs-xcln-card">
            {data.map((newsData, index) => (
              <Col lg="12 my-1" xs="12 my-1" key={`job-info${index.toString()}`}>
                <Card className="cards-news-public">
                  <CardBody className="padding-zero">
                    <div className="new-layout-newsletter">
                      <Row className="align-items-center">
                        <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
                          <div className="align-center">
                            <CardImg
                              alt="..."
                              src={newsData.previewUrl}
                              className="public-newsletter-img"
                            />
                          </div>
                        </Col>
                        <Col lg={{ size: 8, order: 1 }} xs={{ order: 1 }}>
                          <div className="align-text-justify">
                            <Button disabled={clickedIndex === newsData.MediaId} onClick={() => handleGetNewsDetails(newsData.descHtmlUrl, newsData.subTtl, newsData.MediaId)} className="public-newsletter-button-header">
                              {newsData.subTtl}
                            </Button>
                          </div>
                          <div className="align-start">
                            <span className="text-lite">
                              {newsData.author.fNme} {newsData.author.lNme}
                            </span>
                            <span className="text-lite mx-2">
                              |
                            </span>
                            <span className="text-lite">
                              {formatUserDate(newsData.updtOn)}
                            </span>
                          </div>
                          <div className="align-text-justify pt-3">
                            <span className="dash-sadmin-news-description">
                              {newsData.subLine}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className="table-norecords">
          <span className="table-text-no">No Newsletter Found</span>
        </div>
      )}
  </div>
);

export default AllNewsletter;
