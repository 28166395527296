import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Row, Col, Input, Button, InputGroup, NavLink, Form,
  CardImg,
  Label,
} from 'reactstrap';
import './register.scss';
import { RootState } from 'typesafe-actions';
import React, { useState } from 'react';
import {
  clearRegisterXCLNPostReq, getLoginToken, registerXCLNReq,
} from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { LoaderData } from '../../../../utils/loader';
import { RegisterXCLNInput } from '../../../../services/staffing/xcelian/register-xcelian/register.types';
import { DialCountrInfo } from '../../../../utils/MetaData/country';
import Modal from '../../../../utils/Modal/Modal';

export const RegisterXcelian: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [htmlTerm, setHtmlTerm] = React.useState('');
  const [isXcelRegister, setIsXcelRegister] = useState(false);
  const [invalidField, setInvalidField] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const registerResponse = useSelector((state: RootState) => state.campusXcel.registerXcelian);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [registerXCLNData, setRegisterXCLNData] = useState<RegisterXCLNInput>({
    fNme: '',
    lNme: '',
    dlCd: '+91',
    ph: '',
    eId: '',
    isAgreeTrm: false,
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };

  const loginPage = () => {
    history('/xcelian/login');
    window.scrollTo(0, 0);
  };

  const dialNameoptions = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));
  const selectedDialNameOptions = dialNameoptions.find(
    (option) => option.value === registerXCLNData.dlCd,
  );
  const handleSelectDialData = (e:any) => {
    setRegisterXCLNData({ ...registerXCLNData, dlCd: e.value });
  };

  const handleTermChange = (e: any) => {
    setRegisterXCLNData({ ...registerXCLNData, isAgreeTrm: e.target.checked });
  };

  const handleFieldChangeRegister = (e:any) => {
    const fieldName = e.target.name;
    setRegisterXCLNData({ ...registerXCLNData, [fieldName]: e.target.value });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setIsXcelRegister(true);
    const validEmail = emailRegex.test(registerXCLNData.eId);
    const validPhone = phoneRegex.test(registerXCLNData.ph);
    if (validEmail && validPhone) {
      setRegisterLoader(true);
      dispatch(getLoginToken({
        userName: '',
        passKey: '',
        isPublic: true,
        uTp: UserType.CXAPI,
        requestType: APIHeader.USER_LOGIN,
      }));
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '' && isXcelRegister) {
      setRegisterLoader(true);
      dispatch(registerXCLNReq({
        inputBody: registerXCLNData,
        requestType: APIHeader.REQ_ADD_XCELIAN_CAND,
        uTp: UserType.XCLN,
        token: loginTokenData.token,
      }));
    }
  }, [loginTokenData.isError, loginTokenData.token, isXcelRegister]);

  React.useEffect(() => {
    if (registerResponse.error && registerResponse.message !== '' && isXcelRegister) {
      dispatch(clearRegisterXCLNPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerResponse.message);
      setIsXcelRegister(false);
    }
  }, [registerResponse.message, registerResponse.error, isXcelRegister]);

  React.useEffect(() => {
    if (!registerResponse.error && registerResponse.data !== '' && isXcelRegister) {
      dispatch(clearRegisterXCLNPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setRegisterXCLNData({
        fNme: '',
        lNme: '',
        dlCd: '+91',
        ph: '',
        eId: '',
        isAgreeTrm: false,
      });
      if (registerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Registration has been successfully completed. Kindly review your email for additional information.');
      }
      setSuccessShowAlert(true);
      setIsXcelRegister(false);
    }
  }, [registerResponse.data, registerResponse.error, isXcelRegister]);

  React.useEffect(() => {
    const termFile = '/xcelian.html';
    const fetchHtml = async () => {
      try {
        const response = await fetch(termFile);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setHtmlTerm(text);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch HTML file:', error);
      }
    };
    fetchHtml();
  }, []);

  return (
    <div className="xcelian-register-bg">
      { registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <NavLink href="/jobs" to="/jobs" className="nav-logo">
        <CardImg
          style={{
            height: '45px', width: 'auto',
          }}
          src="/campusXcel.png"
          alt="campusXcelLogo"
        />
      </NavLink>
      <div className="my-1">
        <div className="align-center mx-3">
          <span className="form-header-text">
            Register as Xcelian
          </span>
        </div>
        <Row className="align-center margin-mob-top pt-3">
          <Col lg={{ size: 4, order: 1 }} xs={{ size: 11, order: 3 }}>
            <div className="mx-4">
              <CardImg
                src="/images/xcelian/xcelian-register.png"
                alt="menu-logo"
                className="mx-1"
              />
            </div>
          </Col>
          <Col lg={{ size: 5, order: 3 }} xs={{ size: 11, order: 1 }}>
            <Form onSubmit={handleSubmit}>
              <div className="pt-2 register-card">
                <Row className="align-center">
                  <Col lg="6" className="pt-4">
                    <Input
                      placeholder="First Name"
                      type="text"
                      required
                      name="fNme"
                      value={registerXCLNData.fNme}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                  </Col>
                  <Col lg="5" className="pt-4">
                    <Input
                      placeholder="Last Name"
                      type="text"
                      required
                      name="lNme"
                      value={registerXCLNData.lNme}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                  </Col>
                  <Col lg="11" className="pt-4">
                    <Input
                      placeholder="Email Id"
                      type="text"
                      required
                      name="eId"
                      value={registerXCLNData.eId}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                    <span className="text-lite-grey align-start pt-1">
                      Email for all communications
                    </span>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <InputGroup>
                      <Select
                        value={registerXCLNData.dlCd === '' ? null : selectedDialNameOptions}
                        defaultInputValue="+91"
                        options={dialNameoptions}
                        onChange={handleSelectDialData}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#8B0000' },
                            border: '1px solid #DDE0E4',
                            minHeight: '50px',
                            borderRadius: '10px',
                            padding: 1,
                            fontSize: '15px',
                            fontWeight: 400,
                            color: '#575656',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            // border: '1px solid #F9CB9C',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No Dial Code Found'}
                      />
                      <Input
                        placeholder="Phone Number"
                        type="number"
                        required
                        name="ph"
                        value={registerXCLNData.ph}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="campus-input mx-1"
                      />
                    </InputGroup>
                    <span className="text-lite-grey pt-1 align-start ml">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</span>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <div className="align-start">
                      <Label check>
                        <Input
                          type="checkbox"
                          disabled={registerLoader}
                          onChange={handleTermChange}
                          className="campus-check-box ml pt-2 cursor-pointer"
                        />{' '}
                      </Label>
                      <span className="text-lite-grey">I have read the <Button className="button-transparent" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
                    </div>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <div className="text-right-login-campus-register align-end my-1">
                      <Button className="campus-button mx-4" disabled={!registerXCLNData.isAgreeTrm}>
                        Register
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row />
              </div>
            </Form>
            <div className="align-center pt-2">
              <span className="mx-3 text-lite-grey">Already signed up ? <Button className="button-transparent" onClick={loginPage}>Login </Button></span>
            </div>
            <Modal
              show={modalConfirm || false}
              onClose={() => (false)}
              disCancel
              targetId="termCond"
              title=""
              size="XL"
            >

              <div
                className="mx-5"
                  // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: htmlTerm }}
              />
              <div className="align-center my-3">
                <Button className="campus-button-all mx-1" onClick={handleTermUse}>
                  Accept
                </Button>
              </div>
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default RegisterXcelian;
