export enum ApiLIMIT{
    LIMIT = 30
}
export enum UserType {
  // NEW Start
  CMPS='CMPS',
  CXADM='CXADM',
  CXAPI='CXAPI',
  RCRT='RCRT',
  CORP='CORP',
  XCLN='XCLN',
  SKLMENT='SKLMENT',
  COMMN='COMMN',
  PUBLIC='PUBLIC',
}
export enum APIHeader{
    USER_LOGIN = 'user_login',
    REQ_GENERATE_OTP = 'generate_otp',
    REQ_VERIFY_OTP = 'verify_otp',
    REQ_USER_PROFILE_INFO = 'user_profile_info',
    REQ_FORGOT_PASSWORD = 'forgot_password',
    REQ_ADD_CONTACT_FORM = 'add_contact_form',
    REQ_ONBOARD_CLIENT = 'onboard_client',
    REQ_ALL_CLIENT = 'get_all_client',
    REQ_ONBOARD_CLIENT_USER = 'onboard_client_user',
    REQ_GET_CLIENT_USER = 'get_client_user',
    REQ_DELETE_CLIENT = 'delete_client',
    REQ_ADD_CMPS_CANDIDATE = 'add_campus_candidate',
    REQ_ALL_CAMPUS_CAND = 'get_all_campus_candidates',
    REQ_UPDATE_CANDIDATE_STATUS = 'update_candidate_status',
    REQ_GET_CMPS_CANDIDATE_ID = 'get_cmps_candidate_id',
    REQ_UPDATE_OBJECTIVE = 'update_objective',
    REQ_UPDATE_VIDEO = 'update_video',
    REQ_UPDATE_HOBBIES = 'update_hobbies',
    REQ_UPDATE_CERT = 'update_cert',
    REQ_META_CITY_PIN = 'get_city_pincode',
    REQ_ADD_JOB_PHASE1 = 'add_job_phase1',
    REQ_UPDATE_JOB_BY_PHASE = 'update_job_by_phase',
    REQ_UPDATE_JOB_STATUS = 'update_job_status',
    REQ_ALL_JOBS_PRIVATE = 'get_all_jobs_private',
    REQ_ALL_JOBS_PUBLIC = 'get_all_jobs_public',
    REQ_ALL_JOBS_XCELIAN = 'get_all_jobs_xcelian',
    REQ_UPDATE_PASSWORD = 'update_password',
    REQ_ADD_XCELIAN_CAND = 'add_xcelian_candidate',
    REQ_GET_ALL_POST='get_all_posts',
    REQ_ADD_POST_DB = 'add_post',
    REQ_ADD_LIKE = 'add_like',
    REQ_ADD_FOLLOWER = 'add_follower',
    REQ_ADD_COMMENTS = 'add_comments',
    REQ_GET_ALL_COMMENTS = 'get_all_comments',
    REQ_GET_ALL_USER_LIKES = 'get_all_liked_users',
    REQ_ADD_BATCH = 'add_acad_batch',
    REQ_GET_ALL_ACAD_BATCH = 'get_all_acad_batch',
    REQ_GET_JOB_BY_ID = 'get_job_by_id',
    REQ_GET_JOB_DETAILS_BY_ID = 'get_job_details_by_id',
    REQ_ALL_METADATA = 'get_all_metadata',
    REQ_UPDATE_INTERVIEW_STATUS = 'update_interview_status',
    REQ_UPDATE_RECRUIT_INTERVIEW_STATUS = 'update_recruit_interview_status',
    REQ_PARSE_RESUME_DATA = 'parse_resume_data',
    REQ_LOAD_RESUME_DATA = 'load_resume_data',
    REQ_ALL_INTERVIEW_RECRUIT = 'get_all_interview_recruit',
    REQ_UPDATE_XCELIAN_RESUME_FILE = 'update_xcelian_resume_file',
    REQ_UPDATE_MEDIA = 'update_media',
    REQ_ALL_MEDIA= 'get_all_media',
    REQ_UPDATE_MEDIA_STATUS = 'update_media_status',
    REQ_ADD_SUBSCRIBER = 'add_subscriber',
    REQ_GET_DASHBOARD_INFO = 'get_dashboard_info',
    REQ_GET_STAFF_DASHBOARD_INFO = 'get_staff_dashboard_info',
}
export enum APIParam{
    DIALING_META = 'dialing_code_meta',
    DIFF_ABLED_META = 'diff_abled_meta',
    EXT_ORG_META = 'ext_org_meta',
    JOB_TITLE_META = 'job_title_meta',
    SKILLS_META = 'skills_meta',
    COLLEGE_META = 'college_meta',
    COURSE_META = 'course_meta',
    CITY_META = 'city_meta',
    AVATAR_IMAGE = 'AVATAR',
    VIDEO = 'VIDEO'
}

export const DiffAbledTypeInfo = [
  { label: 'Not Applicable', value: 'NONE' },
  { label: 'Blindness', value: 'BLD' },
  { label: 'Low-vision', value: 'LVS' },
  { label: 'Leprosy Cured Persons', value: 'LCP' },
  { label: 'Hearing Impairment (deaf and hard of hearing)', value: 'HIM' },
  { label: 'Locomotor Disability', value: 'LDT' },
  { label: 'Dwarfism', value: 'DWR' },
  { label: 'Intellectual Disability', value: 'IDT' },
  { label: 'Mental Illness', value: 'MIS' },
  { label: 'Autism Spectrum Disorder', value: 'ASD' },
  { label: 'Cerebral Palsy', value: 'CPE' },
  { label: 'Muscular Dystrophy', value: 'MDY' },
  { label: 'Chronic Neurological Conditions', value: 'CNC' },
  { label: 'Specific Learning Disabilities', value: 'SLD' },
  { label: 'Multiple Sclerosis', value: 'MSS' },
  { label: 'Speech and Language Disability', value: 'SDL' },
  { label: 'Thalassemia', value: 'THL' },
  { label: 'Hemophilia', value: 'HMA' },
  { label: 'Sickle Cell Disease', value: 'SCD' },
  { label: 'Multiple Disabilities Including Deaf Blindness', value: 'MDD' },
  { label: 'Acid Attack Victim', value: 'AAV' },
  { label: 'Parkinson s Disease', value: 'PSD' },
  { label: 'Unknown', value: 'UNK' },
];

export const CmntTypeInfo = [
  { label: 'Everyone', value: 'all' },
  // { label: 'My Group', value: 'group' },
  { label: 'None (None can comment)', value: 'none' },
];

export const ListCourseType = [
  { label: 'Diploma', value: 'DIPLOMA' },
  { label: 'Doctorate', value: 'PHD' },
  { label: 'Masters', value: 'MASTERS' },
  { label: 'Post Graduation(PG)', value: 'PG' },
  { label: 'Under Graduate(UG)', value: 'UG' },
];

export const ListEmploymentStatus = [
  { label: 'Full-Time Employee', value: 'FTE' },
  { label: 'Part-Time Employee', value: 'PTE' },
  { label: 'Freelancer', value: 'FRE' },
  { label: 'Self-Employed', value: 'SFE' },
  // { label: 'Temporary Employee', value: 'TME' },
  // { label: 'Intern', value: 'INT' },
  { label: 'Volunteer', value: 'VNT' },
];

export const ListExpYrs = [
  { label: '0-3 Years', value: 0 },
  { label: '3-5 Year', value: 1 },
  { label: '5-8 Year', value: 2 },
  { label: '8-12 Year', value: 3 },
  { label: '12-15 Year', value: 4 },
  { label: '15-18 Year', value: 5 },
  { label: '18-22 Year', value: 6 },
  { label: '22-25 Year', value: 7 },
  { label: '25-30 Year', value: 8 },
  { label: '30-35 Year', value: 9 },
  { label: '35-40 Year', value: 10 },
  { label: '40+ Year', value: 11 },
];

export const FilterLocItems = [
  { label: 'Mumbai', value: 'MUM' },
  { label: 'Navi Mumbai', value: 'NMB' },
  { label: 'Delhi/NCR', value: 'DEL' },
  { label: 'Pune', value: 'PUN' },
  { label: 'Banglore', value: 'BAN' },
  { label: 'Kolkata', value: 'KOL' },
  { label: 'Hyderabad', value: 'HYD' },
  { label: 'Chennai', value: 'CHE' },
  { label: 'Gurugram', value: 'GUR' },
  { label: 'Noida', value: 'NOI' },
];

export const FilterIndusItems = [
  { label: 'FMCG', value: 'FMCG' },
  { label: 'IT & Consulting', value: 'ITC' },
  { label: 'Retail', value: 'RET' },
  { label: 'Software Product', value: 'SPROD' },
  { label: 'Food Processing', value: 'FDPR' },
  { label: 'Travel & Tourism', value: 'TAT' },
  { label: 'Internet', value: 'INT' },
];

export const FilterDeptItems = [
  { label: 'Finance Accounting', value: 'FA' },
  { label: 'Sales & Business', value: 'SBD' },
  { label: 'Marketing Communication', value: 'MCN' },
  { label: 'Procurement Supply Chain', value: 'PSC' },
  { label: 'Human Resources', value: 'HRS' },
  { label: 'Prod, Manufacturing & Engineering', value: 'PME' },
];

export const ListCityTier = [
  { label: 'Tier-1', value: 1 },
  { label: 'Tier-2', value: 2 },
  { label: 'Tier-3', value: 3 },
];
export const ListLoginAccess = [
  { label: 'Approve Access', value: 'APPR' },
  { label: 'Pending Approval', value: 'PADM' },
  { label: 'Block User', value: 'NACC' },
];

export const ListJobType = [
  { label: 'Full-Time', value: 'FT' },
  { label: 'Part-Time', value: 'PT' },
  { label: 'Contract', value: 'CONT' },
  { label: 'Temporary', value: 'TEMP' },
  { label: 'Internship', value: 'INT' },
  { label: 'Freelance', value: 'FRL' },
  { label: 'Volunteer', value: 'VOL' },
  { label: 'Seasonal', value: 'SEAS' },
  { label: 'Per Diem', value: 'PRD' },
  { label: 'Consultant', value: 'CNSL' },
  { label: 'Remote', value: 'REMT' },
  { label: 'On-Site', value: 'ONS' },
  { label: 'Apprenticeship', value: 'APP' },
  { label: 'Commission-Based', value: 'COMM' },
  { label: 'Work From Home', value: 'WFH' },
  { label: 'Flexible Hours', value: 'FLEX' },
  { label: 'Shift-Based', value: 'SHIFT' },
  { label: 'Casual', value: 'CASL' },
  { label: 'Entry-Level', value: 'ENTL' },
  { label: 'Executive', value: 'EXEC' },
  { label: 'Management', value: 'MGMT' },
  { label: 'Senior-Level', value: 'SRLV' },
  { label: 'Temp-to-Perm', value: 'TTP' },
];

export const ListJobSchedule = [
  { label: 'Shift-Based', value: 'SHFT' },
  { label: 'Flexible', value: 'FLEX' },
  { label: 'Remote', value: 'REMT' },
  { label: 'Temporary', value: 'TEMP' },
  { label: 'On-Call', value: 'ONC' },
  { label: 'Weekend', value: 'WKND' },
  { label: 'Overnight', value: 'OVRN' },
  { label: 'Half-Day Flex', value: 'HDFL' },
  { label: 'Morning Shift', value: 'MORN' },
  { label: 'Normal Shift', value: 'NORM' },
  { label: 'UK Shift', value: 'UKSH' },
  { label: 'US Shift', value: 'USSH' },
  { label: 'EU Shift', value: 'EU' },
  { label: 'Asia Shift', value: 'ASIA' },
  { label: 'Split Shift', value: 'SPLT' },
  { label: 'Others', value: 'OTH' },
];

export const ListVacancies = Array.from({ length: 20 }, (_, index) => {
  const num = index + 1;
  return { label: num, value: num };
});

export const ListExpRange1 = Array.from({ length: 36 }, (_, index) => {
  const num = index;
  return { label: num, value: num };
});

export const ListExpRange2 = Array.from({ length: 35 }, (_, index) => {
  const num = index + 1;
  return { label: num, value: num };
});

export const RecruitmentTimeline = [
  { label: '1 to 3 Days', value: 'DYS3' },
  { label: '3 to 7 Days', value: 'DYS7' },
  { label: '1 to 2 Weeks', value: 'DYS14' },
  { label: '2 to 4 Weeks', value: 'DYS28' },
  { label: 'More than 4 Weeks', value: 'NLIMIT' },
];

export const WorkPrefrence = [
  { label: 'Work From Office', value: 'CLNT' },
  { label: 'Work From Home', value: 'HOME' },
  { label: 'Hybrid Work From Anywhere', value: 'HYBRD' },
  { label: 'Unknown', value: 'UNK' },
];

export const PayStructure = [
  { label: 'Starting Amount', value: 'SAMNT' },
  { label: 'Maximum Amount', value: 'MXAMNT' },
  { label: 'Exact Amount', value: 'EXAMNT' },
];

export const SuppPayType = [
  { label: 'Performance Bonus', value: 'PBNS' },
  { label: 'Overtime Pay', value: 'OTPY' },
  { label: 'Holiday Pay', value: 'HDPY' },
  { label: 'Commission', value: 'COMM' },
  { label: 'Shift Differential', value: 'SDFR' },
  { label: 'Referral Bonus', value: 'RFBN' },
  { label: 'Incentive Pay', value: 'INPY' },
  { label: 'Hazard Pay', value: 'HZPY' },
  { label: 'Travel Allowance', value: 'TRAV' },
  { label: 'Retention Bonus', value: 'RTBN' },
  { label: 'Quarterly Bonus', value: 'QBNS' },
  { label: 'Joining Bonus', value: 'JBNS' },
  { label: 'Shift Allowance', value: 'SALW' },
  { label: 'Yearly Bonus', value: 'YBNS' },
  { label: 'Others', value: 'OTRS' },
];

export const AddonPerkType = [
  { label: 'Health Insurance', value: 'HTHI' },
  { label: 'Life Insurance', value: 'LFIN' },
  { label: 'Dental Insurance', value: 'DNTI' },
  { label: 'Vision Insurance', value: 'VSIN' },
  { label: 'Retirement Plan', value: 'RTRP' },
  { label: 'Paid Time Off', value: 'PTOF' },
  { label: 'Employee Discount', value: 'EDSC' },
  { label: 'Gym Membership', value: 'GYMM' },
  { label: 'Wellness Program', value: 'WLPR' },
  { label: 'Remote Work', value: 'RMWK' },
  { label: 'Stock Options', value: 'STOP' },
  { label: 'Childcare Assistance', value: 'CHAS' },
  { label: 'Tuition Reimbursement', value: 'TURB' },
  { label: 'Transportation Allowance', value: 'TRAL' },
  { label: 'Flexible Hours', value: 'FLHR' },
  { label: 'Parental Leave', value: 'PLVE' },
  { label: 'Employee Assistance Program', value: 'EAPG' },
  { label: 'Professional Development', value: 'PRDV' },
  { label: 'Meal Allowance', value: 'MEAL' },
  { label: 'Mobile Reimbursement', value: 'MORB' },
  { label: 'Internet Reimbursement', value: 'INRB' },
  { label: 'Work From Home', value: 'WFHM' },
  { label: 'Provident Fund', value: 'PRFD' },
  { label: 'Computer Assistance', value: 'COMP' },
  { label: 'Leave Encashment', value: 'LVEN' },
  { label: 'Flexible Schedule', value: 'FLSC' },
  { label: 'Others', value: 'OTRS' },
];

export const AmountTpye = [
  { label: 'Per Hour', value: 'PRH' },
  { label: 'Per Day', value: 'PRD' },
  { label: 'Per Week', value: 'PRW' },
  { label: 'Per Month', value: 'PRM' },
  { label: 'Per Year', value: 'PRY' },
];

export const AmountSLashTpye = [
  { label: '/ Hr', value: 'PRH' },
  { label: '/ Day', value: 'PRD' },
  { label: '/ Week', value: 'PRW' },
  { label: '/ Mn', value: 'PRM' },
  { label: '/ Yr', value: 'PRY' },
];

export const numberToWords = (input: string | number, currency = 'INR'): string => {
  let num = typeof input === 'string' ? parseInt(input, 10) : input;

  if (Number.isNaN(num)) return 'Invalid number';
  if (num === 0) return 'Zero';

  const belowTwenty = [
    '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
    'Seventeen', 'Eighteen', 'Nineteen',
  ];
  const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  const words = (n: number): string => {
    if (n < 20) return `${belowTwenty[n]} `;
    if (n < 100) return `${tens[Math.floor(n / 10)]} ${words(n % 10)}`;
    if (n < 1000) return `${belowTwenty[Math.floor(n / 100)]} Hundred ${words(n % 100)}`;
    return '';
  };

  let result = '';

  // Handle crores
  if (num >= 10000000) {
    result += `${words(Math.floor(num / 10000000))} Crore `;
    num %= 10000000;
  }

  // Handle lakhs
  if (num >= 100000) {
    result += `${words(Math.floor(num / 100000))} Lakh `;
    num %= 100000;
  }

  // Handle thousands
  if (num >= 1000) {
    result += `${words(Math.floor(num / 1000))} Thousand `;
    num %= 1000;
  }

  // Handle hundreds and below
  if (num > 0) {
    result += words(num);
  }

  // Append currency
  const currencyName = currency === 'INR' ? 'Rupees' : currency === 'USD' ? 'Dollars' : currency;
  return `${result.trim()} ${currencyName}`.trim();
};

export const InterviewStatusTpye = [
  { label: 'Applied', value: 'APPLD' },
  { label: 'Screening', value: 'SCRN' },
];

export const formatUserDate = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit', // Valid: '2-digit' or 'numeric'
    month: 'short', // Valid: '2-digit', 'short', 'long', 'numeric'
    year: 'numeric', // Valid: 'numeric' or '2-digit'
    hour: '2-digit', // Valid: '2-digit' or 'numeric'
    minute: '2-digit', // Valid: '2-digit' or 'numeric'
    hour12: true, // 12-hour clock format
  };

  return new Intl.DateTimeFormat('en-GB', options).format(date);
};
