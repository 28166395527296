import React from 'react';
import { useNavigate } from 'react-router';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart, ArcElement, Tooltip, Legend,
} from 'chart.js';
import {
  Button,
  Card, CardImg, Col, Row,
} from 'reactstrap';
import { getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import NewsLetterXcelianComponent from '../newsletter/newsletter.component';

Chart.register(ArcElement, Tooltip, Legend);

export const XcelianDashboard: React.FC = () => {
  const history = useNavigate();
  const tokenData = getTokenFromLocalStorage();
  const [dataArrayAccountDough] = React.useState<number[]>([100, 0]); // setDataArrayAccountDough
  const isMobileJob = window.innerWidth <= 468;
  const dataAccountDough = {
    labels: ['Pending', 'Completed'], // These will be hidden on the chart
    datasets: [
      {
        data: dataArrayAccountDough,
        backgroundColor: ['#f8d6d6', '#038e08'],
        hoverBackgroundColor: ['#f8d6d6', '#038e08'],
      },
    ],
  };

  const optionsAccountDough = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as any,
        intersect: true,
        backgroundColor: '#383838 ',
        borderColor: '#959191',
        borderWidth: 1,
        font: {
          size: 13,
          weight: 'bold',
        },
        bodyFont: {
          size: 13,
        },
        callbacks: {
          title: () => '',
          label: (context: any) => {
            const value = context.parsed || 0;
            return `${value.toString().replace(/(\d)(?=(\d\d)+\d$)/g, '$1,')} %`; // Show only value in tooltip
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
  };
  const handleResumeUrl = () => {
    history('/xcelian/exclusive/my-resume');
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className="allign-start ml my-2">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/dashboard.png" alt="batches" />
        <span className="text-lite-grey ml">DASHBOARD</span>
      </div>
      <div>
        {!isMobileJob
          ? (
            <Row>
              <Col lg="12">
                <Row>
                  <Col lg="9">
                    <div className="align-start ml-1 pt-5">
                      <Button
                        className="button-transparent-jobs mx-1"
                        // onClick={handleJobPost}
                      >
                        <div className="my-1 mx-2">
                          Post Job
                        </div>
                      </Button>
                      <Button
                        className="button-transparent-jobs mx-1"
                        // onClick={handleJobPost}
                      >
                        <div className="my-1 mx-2">
                          My Saved Jobs
                        </div>
                      </Button>
                      <Button
                        className="button-transparent-jobs mx-2"
                        // onClick={handleJobPost}
                      >
                        <div className="my-1 mx-1">
                          My Interviews
                        </div>
                      </Button>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="mb-2">
                      <Card className="xcelian-card cursor-pointer" onClick={handleResumeUrl}>
                        <Row>
                          <Col lg="8">
                            <div className="pt-2 ml-1">
                              <span className="text-lite-grey-sub">Current Resume status</span>
                            </div>
                            <div className="ml-1">
                              <span className="text-lite-grey">Completed: 0%</span>
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="my-2 mr">
                              <Doughnut data={dataAccountDough} options={optionsAccountDough} />
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg="12">
                <NewsLetterXcelianComponent token={tokenData} />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12" lg="12">
                Mobile Header all Activity
              </Col>
            </Row>
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default XcelianDashboard;
