import { ResumeParserPutResponse, ResumeParserPutResults } from './update.resume.parser.service.types';

export const ResumeParserPutTransform: (response: ResumeParserPutResponse) => ResumeParserPutResults = (certResults) => {
  const { data, is_error, message } = certResults;
  const result :ResumeParserPutResults = {
    data: {
      JobCd: '',
      JobId: '',
      forClnt: '',
      jobNme: '',
      uId: '',
      fNme: '',
      lNme: '',
      eId: '',
      phn: '',
      dlCd: '',
    },
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
    result.data = data;
  }
  return result;
};
