import React from 'react';
import {
  Button,
  CardImg,
  Col,
  Input,
  Row,
} from 'reactstrap';
import '../dashboard.scss';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddNewsLetter from './add.newsletter';
import { NewsletterInput } from '../../../../../services/sadmin/newsletter/update-newsletter/update.newsletter.types';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import { LoaderData } from '../../../../../utils/loader';
import { APIHeader, UserType } from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import {
  clearEditNewsletterReq,
  clearEditNewsletterStatusReq,
  clearListNewsletterReq, editNewsletterReq, editNewsletterStatusReq, listNewsletterReq,
  uploadS3FilesReq,
} from '../../../../../store/campusXcel/actions';
import { getConfig } from '../../../../../utils/config/config';
import { UploadS3FilesDataInput } from '../../../../../services/common/upload-s3-files/upload-s3-files.types';
import { AllNewslettersResult } from '../../../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import AllNewsletter from './list.newsletter';
import DetailsNewsletter from './details.newsletter';
import Offcanvas from '../../../../../utils/OffCanvas/OffcanvasComponent';
import Modal from '../../../../../utils/Modal/Modal';
import { NewsletterStatusInput } from '../../../../../services/sadmin/newsletter/update-newsletter-status/update.newsletter.status.types';

const { AWS_CXUSER_BUCKET } = getConfig();

export type TabProps = {
    activeTab: string;
};

export const NewsLetterComponent: React.FC<TabProps> = ({ activeTab }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const isMobileJob = window.innerWidth <= 468;
  const [loading, setLoading] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [newsLetterTab, setNewsLetterTab] = React.useState('LIST-NEWS');
  const [htmlUrl, setHtmlUrl] = React.useState('');
  const [htmlTitle, setHtmlTitle] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [viewMode, setViewMode] = React.useState(true);
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState('');
  const [isStatusConfirmed, setIsStatusConfirmed] = React.useState(false);
  const [isStatusData, setIsStatusData] = React.useState('');
  const [isMediaIdData, setIsMediaIdData] = React.useState('');
  const listNewsletterResponse = useSelector((state: RootState) => state.campusXcel.listNewsletter);
  const updateNewsletterResponse = useSelector((state: RootState) => state.campusXcel.updateNewsletter);
  const updateNewsletterStatusResponse = useSelector((state: RootState) => state.campusXcel.updateNewsletterStatus);
  const [updateNewsLetter, setUpdateNewsLetter] = React.useState<NewsletterInput>({
    newsKey: [],
    fileName: '',
    subLine: '',
    subTtl: '',
    logoFile: '',
    medTp: 'NWSLTR', // NWSLTR or PODCST allowed
  });
  const [listNewsLetter, setListNewsLetter] = React.useState<AllNewslettersResult>({
    tCnt: 0,
    fCnt: 0,
    mediaData: [],
    lastEvalKey: {
      MediaTp: '',
      MediaId: '',
    },
  });
  const [updateNewsLetterStatus, setUpdateNewsLetterStatus] = React.useState<NewsletterStatusInput>({
    MediaTp: '',
    MediaId: '',
    medSts: '',
  });

  const [previewImageFile, setPreviewImageFile] = React.useState<UploadS3FilesDataInput>({ fileData: new FormData() });
  const [newsHtml, setNewsHtml] = React.useState<UploadS3FilesDataInput>({ fileData: new FormData() });

  const toggleResumeCloseTemplate = () => {
    setIsClickClose(false);
    setClickedIndex('');
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  React.useEffect(() => {
    if (activeTab === 'DASH-HOME') {
      setLoading(true);
      dispatch(listNewsletterReq({
        requestType: APIHeader.REQ_ALL_MEDIA,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        mediaTp: 'NWSLTR',
        medSts: '',
      }));
    }
  }, [activeTab]);

  const fetchMoreData = () => {
    setLoading(true);
    const searchFlag = isSearchQuery !== '' ? 'YES' : 'NO';
    const searchParam = isSearchQuery !== '' ? searchText : '';
    dispatch(listNewsletterReq({
      requestType: APIHeader.REQ_ALL_MEDIA,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: evalKeyText,
      evalTp: evalTpText,
      isFilter: 'NO',
      isSearch: searchFlag,
      searchBy: searchParam,
      token: tokenData,
      mediaTp: 'NWSLTR',
      medSts: '',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setTotalRecords(0);
    // setPagedData([]);
    setLoading(true);
    setIsSearchQuery(searchText);
    dispatch(listNewsletterReq({
      requestType: APIHeader.REQ_ALL_MEDIA,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      mediaTp: 'NWSLTR',
      medSts: '',
    }));
  };

  React.useEffect(() => {
    if (listNewsletterResponse.isError && listNewsletterResponse.message !== '') {
      setLoading(false);
      setAlertMsg(listNewsletterResponse.message);
      setErrorShowAlert(true);
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.isError, listNewsletterResponse.message]);

  React.useEffect(() => {
    if (!listNewsletterResponse.isError && listNewsletterResponse.message === 'executed') {
      setLoading(false);

      if (
        listNewsletterResponse.data
        && Object.keys(listNewsletterResponse.data).length > 0
        && Array.isArray(listNewsletterResponse.data.mediaData)
      ) {
        setListNewsLetter((prevState) => {
          // Reset if it's a search case
          const newMediaData = isSearchQuery
            ? listNewsletterResponse.data.mediaData // Fresh data for search
            : listNewsletterResponse.data.mediaData.filter(
              (newItem) => !prevState?.mediaData.some(
                (existingItem) => existingItem.MediaId === newItem.MediaId,
              ),
            );

          return {
            tCnt: listNewsletterResponse.data.tCnt,
            fCnt: listNewsletterResponse.data.fCnt,
            mediaData: isSearchQuery ? newMediaData : [...(prevState?.mediaData || []), ...newMediaData], // Append filtered media data if not searching
            lastEvalKey: listNewsletterResponse.data.lastEvalKey,
          };
        });

        if (listNewsletterResponse.data.lastEvalKey !== null) {
          setEvalKeyText(listNewsletterResponse.data.lastEvalKey.MediaId);
          setEvalTpText(listNewsletterResponse.data.lastEvalKey.MediaTp);
        } else {
          setEvalKeyText('');
          setEvalTpText('');
        }

        setTotalRecords(listNewsletterResponse.data.tCnt);
      }

      // Clear response after processing
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.data, listNewsletterResponse.isError, listNewsletterResponse.message, isSearchQuery]);

  const handleSubmitClientUser = (value: string) => {
    setNewsLetterTab(value);
  };

  const handleChangeEditValue = () => {
    setViewMode(!viewMode);
  };

  const handleUploadNewsletter = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setLoading(true);
    dispatch(editNewsletterReq({
      inputBody: updateNewsLetter,
      requestType: APIHeader.REQ_UPDATE_MEDIA,
      uTp: UserType.CXADM,
      token: tokenData,
      fldUpdtTp: 'ADDNEW',
    }));
  };

  React.useEffect(() => {
    if (updateNewsletterResponse.error && updateNewsletterResponse.message !== '' && activeTab === 'DASH-HOME') {
      setLoading(false);
      dispatch(clearEditNewsletterReq());
      setErrorShowAlert(true);
      // setIsSubmitting(false);
      setAlertMsg(updateNewsletterResponse.message);
    }
  }, [updateNewsletterResponse, activeTab]);

  React.useEffect(() => {
    if (!updateNewsletterResponse.error && updateNewsletterResponse.message === 'executed' && activeTab === 'DASH-HOME') {
      // setIsSubmitting(false);
      const uniqueHtmlFileName = updateNewsletterResponse.fileData.fileHtmlDesc;
      const uniqueLogoPreviewFileName = updateNewsletterResponse.fileData.logoFileName;
      const uploadFiles = () => {
        if (previewImageFile.fileData && newsHtml.fileData) {
          const uploadPromises = [
            dispatch(uploadS3FilesReq({
              bucketNme: AWS_CXUSER_BUCKET,
              folderName: `media/${updateNewsletterResponse.fileData.MediaTp}/${updateNewsletterResponse.fileData.MediaId}`,
              fileNmeInit: uniqueLogoPreviewFileName,
              formData: previewImageFile.fileData,
              requestType: '',
              isEdit: false,
            })),
            dispatch(uploadS3FilesReq({
              bucketNme: AWS_CXUSER_BUCKET,
              folderName: `media/${updateNewsletterResponse.fileData.MediaTp}/${updateNewsletterResponse.fileData.MediaId}`,
              fileNmeInit: uniqueHtmlFileName,
              formData: newsHtml.fileData,
              requestType: '',
              isEdit: false,
            })),
          ];
          Promise.all(uploadPromises).then(() => {
            setLoading(true);
            setSuccessShowAlert(true);
            setAlertMsg('Uploaded Successfully');
            setNewsLetterTab('LIST-NEWS');
            dispatch(listNewsletterReq({
              requestType: APIHeader.REQ_ALL_MEDIA,
              uTp: UserType.CXADM,
              limit: recordsPerPage,
              evalKey: '',
              evalTp: '',
              isFilter: 'NO',
              isSearch: 'NO',
              token: tokenData,
              mediaTp: 'NWSLTR',
              medSts: '',
            }));
          }).catch((err) => {
            setLoading(false);
            setErrorShowAlert(true);
            setAlertMsg(`Upload failed: ${err.message}`);
          });
          dispatch(clearEditNewsletterReq());
        } else {
          setErrorShowAlert(true);
          setAlertMsg('One or both files are undefined, cannot upload.');
          dispatch(clearEditNewsletterReq());
        }
      };

      uploadFiles();
    }
  }, [updateNewsletterResponse, activeTab]);

  // const handleUpdateMediaDetails = (MediaId: string, MediaTp: string) => {
  //   console.log(MediaId, MediaTp);
  // };

  const handleUpdateMediaStatus = (mediaId: string, mediaTp: string, MediaSts: string) => {
    setUpdateNewsLetterStatus({
      ...updateNewsLetterStatus, MediaId: mediaId, MediaTp: mediaTp, medSts: MediaSts,
    });
    setIsStatusConfirmed(true);
    setIsMediaIdData(mediaId);
    if (MediaSts === 'ACTIVE') {
      setIsStatusData('Approve');
    }
    if (MediaSts === 'BLOCKED') {
      setIsStatusData('Block');
    }
  };

  const handleUpdateStatus = (statusData: string) => {
    if (statusData === 'Approve' || statusData === 'Block') {
      if (isMediaIdData === updateNewsLetterStatus.MediaId) {
        setLoading(true);
        dispatch(editNewsletterStatusReq({
          inputBody: updateNewsLetterStatus,
          requestType: APIHeader.REQ_UPDATE_MEDIA_STATUS,
          uTp: UserType.CXADM,
          token: tokenData,
        }));
        setIsStatusConfirmed(false);
      } else {
        setIsStatusConfirmed(false);
        setIsMediaIdData('');
      }
    } else {
      setIsStatusConfirmed(false);
      setIsMediaIdData('');
    }
  };

  React.useEffect(() => {
    if (updateNewsletterStatusResponse.error && updateNewsletterStatusResponse.message !== '' && activeTab === 'DASH-HOME') {
      setLoading(false);
      setIsStatusConfirmed(false);
      dispatch(clearEditNewsletterStatusReq());
      setErrorShowAlert(true);
      setAlertMsg(updateNewsletterStatusResponse.message);
    }
  }, [updateNewsletterStatusResponse, activeTab]);

  React.useEffect(() => {
    if (!updateNewsletterStatusResponse.error && updateNewsletterStatusResponse.message === 'executed' && activeTab === 'DASH-HOME') {
      setLoading(false);
      setIsStatusConfirmed(false);
      dispatch(clearEditNewsletterStatusReq());
      dispatch(clearListNewsletterReq());
      setListNewsLetter({ ...listNewsLetter, mediaData: [] });
      dispatch(listNewsletterReq({
        requestType: APIHeader.REQ_ALL_MEDIA,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        mediaTp: 'NWSLTR',
        medSts: '',
      }));
    }
  }, [updateNewsletterStatusResponse, activeTab]);

  const handleGetNewsDetails = async (UrlHtml: string, htmlTitleDetail: string, MediaId: string) => {
    if (UrlHtml) {
      setLoading(true); // Start loader
      try {
        const response = await fetch(UrlHtml);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setHtmlUrl(text);
        setHtmlTitle(htmlTitleDetail);
        setIsClickClose(true);
        setClickedIndex(MediaId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching news details:', error);
      } finally {
        setLoading(false); // Stop loader
      }
    }
  };

  return (
    <div>
      {loading ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Modal
        show={isStatusConfirmed || false}
        onClose={() => (false)}
        disCancel
        targetId="delCert"
        title=""
        size="SM"
      >
        <div className="my-2 align-center mx-4">
          <span>Do you really want to {isStatusData} ?</span>
        </div>
        <div className="align-center my-3 pt-4">
          <Button className="button-icon" onClick={() => handleUpdateStatus(isStatusData)}>
            {isStatusData}
          </Button>
          <Button className="campus-button-all" onClick={() => handleUpdateStatus('cancel')}>
            CANCEL
          </Button>
        </div>
      </Modal>
      <div>
        {!isMobileJob
          ? (
            <Row>
              <Col lg="12">
                <div className="align-start ml pt-2 mr">
                  {newsLetterTab === 'ADD-NEWS'
                    ? (
                      <Button className="campus-onboard-button" onClick={() => handleSubmitClientUser('LIST-NEWS')}>
                        <CardImg src="/images/icons/previous.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                        <span className="dash-sadmin-fig-text">Back to List</span>
                      </Button>
                    )
                    : null}
                  {newsLetterTab === 'LIST-NEWS'
                    ? (
                      <Button className="campus-onboard-button" onClick={() => handleSubmitClientUser('ADD-NEWS')}>
                        <CardImg src="/images/icons/newsletter.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                        Add New
                      </Button>
                    ) : null}
                </div>
              </Col>
              <Col lg="12">
                <div className="ml pt-2">
                  {newsLetterTab === 'ADD-NEWS'
                    ? (
                      <AddNewsLetter
                        updateNewsLetter={updateNewsLetter}
                        setUpdateNewsLetter={setUpdateNewsLetter}
                        handleUploadNewsletter={handleUploadNewsletter}
                        setAlertMsg={setAlertMsg}
                        setErrorShowAlert={setErrorShowAlert}
                        previewImageFile={previewImageFile}
                        setPreviewImageFile={setPreviewImageFile}
                        newsHtml={newsHtml}
                        setNewsHtml={setNewsHtml}
                      />
                    )
                    : null}
                  {newsLetterTab === 'LIST-NEWS'
                    ? (
                      <div className="pt-2">
                        <Row className="align-center">
                          <Col lg="10">
                            <div className="ml">
                              <span className="text-lite mr">Total Count:</span>{totalRecords}
                            </div>
                            <div className="pt-2 mb-2">
                              <div className="input-wrapper">
                                <Input
                                  type="text"
                                  placeholder="Search..."
                                  value={searchText}
                                  onChange={handleSearchChange}
                                />
                                <Button type="button" onClick={handleSearchProjects}>
                                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </Button>
                              </div>
                            </div>
                            <AllNewsletter
                              clickedIndex={clickedIndex}
                              data={listNewsLetter.mediaData}
                              handleGetNewsDetails={handleGetNewsDetails}
                              handleUpdateMediaStatus={handleUpdateMediaStatus}
                            />
                            <div className="align-center pt-4">
                              {listNewsLetter.mediaData.length > 0
                                ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                            </div>
                          </Col>
                          <Col lg="4">
                            <Offcanvas
                              isOpen={isClickClose}
                              toggle={toggleResumeCloseTemplate}
                            >
                              {viewMode ? (
                                loading ? (
                                  <div>Loading...</div>
                                ) : (
                                  htmlUrl !== '' ? (
                                    <div className="dash-sadmin-news-border">
                                      <DetailsNewsletter
                                        htmlContent={htmlUrl}
                                        htmlTitle={htmlTitle}
                                        handleChangeEditValue={handleChangeEditValue}
                                      />
                                    </div>
                                  ) : null
                                )
                              ) : (
                                'Edit Dataaa'
                              )}
                            </Offcanvas>
                          </Col>
                        </Row>

                      </div>

                    )
                    : null}
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <div className="align-start ml-1 mr pt-2">
                  {newsLetterTab === 'ADD-NEWS'
                    ? (
                      <Button className="campus-onboard-button" onClick={() => handleSubmitClientUser('LIST-NEWS')}>
                        <CardImg src="/images/icons/previous.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                        <span className="dash-sadmin-fig-text">Back to List</span>
                      </Button>
                    )
                    : null}
                  {newsLetterTab === 'LIST-NEWS'
                    ? (
                      <Button className="campus-onboard-button" onClick={() => handleSubmitClientUser('ADD-NEWS')}>
                        <CardImg src="/images/icons/newsletter.png" className="campus-tab-resume-icon-img mx-2" alt="Icon 1" />
                        Add New
                      </Button>
                    ) : null}
                </div>
              </Col>
              <Col xs="12">
                <div className="ml pt-2">
                  {newsLetterTab === 'ADD-NEWS'
                    ? (
                      <AddNewsLetter
                        updateNewsLetter={updateNewsLetter}
                        setUpdateNewsLetter={setUpdateNewsLetter}
                        handleUploadNewsletter={handleUploadNewsletter}
                        setAlertMsg={setAlertMsg}
                        setErrorShowAlert={setErrorShowAlert}
                        previewImageFile={previewImageFile}
                        setPreviewImageFile={setPreviewImageFile}
                        newsHtml={newsHtml}
                        setNewsHtml={setNewsHtml}
                      />
                    )
                    : null}
                  {newsLetterTab === 'LIST-NEWS'
                    ? (
                      <div className="pt-3">
                        <Row>
                          <Col xs="12">
                            <div className="ml">
                              <span className="text-lite mr">Total Count:</span>{totalRecords}
                            </div>
                            <div className="pt-2 mb-2 mr-1 ml">
                              <div className="input-wrapper">
                                <Input
                                  type="text"
                                  placeholder="Search..."
                                  value={searchText}
                                  onChange={handleSearchChange}
                                />
                                <Button type="button" onClick={handleSearchProjects}>
                                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </Button>
                              </div>
                            </div>
                            <AllNewsletter
                              clickedIndex={clickedIndex}
                              data={listNewsLetter.mediaData}
                              handleGetNewsDetails={handleGetNewsDetails}
                              handleUpdateMediaStatus={handleUpdateMediaStatus}
                            />
                            <div className="align-center pt-4">
                              {listNewsLetter.mediaData.length > 0
                                ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                            </div>
                          </Col>
                          <Col xs="12">
                            <Offcanvas
                              isOpen={isClickClose}
                              toggle={toggleResumeCloseTemplate}
                            >
                              {viewMode ? (
                                htmlUrl !== ''
                                  ? (
                                    <div className="dash-sadmin-news-border">
                                      <DetailsNewsletter
                                        htmlContent={htmlUrl}
                                        htmlTitle={htmlTitle}
                                        handleChangeEditValue={handleChangeEditValue}
                                      />
                                    </div>
                                  ) : null
                              )
                                : (
                                  'Edit Dataaa'
                                )}
                            </Offcanvas>

                          </Col>
                        </Row>

                      </div>

                    )
                    : null}
                </div>
              </Col>
            </Row>
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default NewsLetterComponent;
