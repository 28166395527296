import React, { useState } from 'react';
import {
  Button,
  Card, CardImg, Col, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import './dashboard.scss';
import { NewsLetterComponent } from './news-letter/newsletter.component';
import { DashResult } from '../../../../services/sadmin/dashboard/get.sadmin.dashboard.types';
import { clearGetSadminDashReq, getSadminDashReq } from '../../../../store/campusXcel/actions';
import { UserType, APIHeader } from '../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../utils/service/localstorage-service';
import { errorAlert, successAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';

export const SuperAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [activeTab, setActiveTab] = React.useState('DASH-HOME');
  const dashboardSadminResponse = useSelector((state: RootState) => state.campusXcel.getSadminDashboard);
  const [dashboardData, setDashboardData] = React.useState<DashResult>({
    subsCount: 0,
    CMPS: 0,
    RCRT: 0,
    CORP: 0,
    XCLN: 0,
    NWSLTR: 0,
    PDCST: 0,
    SKLMENT: 0,
    jobsCount: 0,
  });

  React.useEffect(() => {
    setLoading(true);
    dispatch(getSadminDashReq({
      uTp: UserType.CXADM,
      requestType: APIHeader.REQ_GET_DASHBOARD_INFO,
      token: tokenData,
    }));
  }, []);

  React.useEffect(() => {
    if (dashboardSadminResponse.isError && dashboardSadminResponse.message !== '') {
      setLoading(false);
      setShowAlert(true);
      setAlertMsg(dashboardSadminResponse.message);
      dispatch(clearGetSadminDashReq());
    }
  }, [dashboardSadminResponse.isError, dashboardSadminResponse.message]);

  React.useEffect(() => {
    if (!dashboardSadminResponse.isError && dashboardSadminResponse.message === 'executed') {
      setLoading(false);
      setDashboardData(dashboardSadminResponse.data);
      // Clear response after processing
      dispatch(clearGetSadminDashReq());
    }
  }, [dashboardSadminResponse.data, dashboardSadminResponse.isError, dashboardSadminResponse.message]);

  return (
    <div>
      {loading ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showAlert ? (
        successAlert(false, alertMsg, showAlert, setShowAlert)
      ) : null}
      <div className="allign-start ml my-2 padding-mob-top-subs">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/dashboard.png" alt="batches" />
        <span className="text-lite-grey ml">DASHBOARD</span>
      </div>
      <div>
        <Row>
          <Col lg="12">
            <Row>
              <Col lg="7">
                <div className="align-start ml-1 pt-5">
                  <Button
                    className={activeTab === 'DASH-HOME' ? 'button-sadmin-filled mx-1' : ' button-sadmin-trans mx-1'}
                    onClick={() => setActiveTab('DASH-HOME')}
                  >
                    <div className="my-1 mx-2">
                      Newsletter
                    </div>
                  </Button>
                  <Button
                    className={activeTab === 'PODCAST' ? 'button-sadmin-filled mx-1' : ' button-sadmin-trans mx-1'}
                    onClick={() => setActiveTab('PODCAST')}
                  >
                    <div className="my-1 mx-2">
                      Podcast
                    </div>
                  </Button>
                  <Button
                    className={activeTab === 'JOBS' ? 'button-sadmin-filled mx-1' : ' button-sadmin-trans mx-1'}
                    onClick={() => setActiveTab('JOBS')}
                  >
                    <div className="my-1 mx-1">
                      Jobs
                    </div>
                  </Button>
                  <Button
                    className={activeTab === 'ADV' ? 'button-sadmin-filled mx-1' : ' button-sadmin-trans mx-1'}
                    onClick={() => setActiveTab('ADV')}
                  >
                    <div className="my-1 mx-1">
                      Advertisement
                    </div>
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg="12">
            <Row>
              <Col lg={{ size: 9, order: 1 }} xs={{ order: 2 }}>
                <div style={{ display: activeTab === 'DASH-HOME' ? 'block' : 'none' }}>
                  <NewsLetterComponent activeTab={activeTab} />
                </div>
                <div style={{ display: activeTab === 'PODCAST' ? 'block' : 'none' }}>
                  PODCAST
                </div>
                <div style={{ display: activeTab === 'ADV' ? 'block' : 'none' }}>
                  Advertisement
                </div>
                <div style={{ display: activeTab === 'JOBS' ? 'block' : 'none' }}>
                  Jobs Listings
                </div>
              </Col>
              <Col lg={{ size: 3, order: 2 }} xs={{ order: 1 }}>
                <div className="align-center mb-2 pt-3">
                  <span className="text-whatsapp">CampusXcel Users</span>
                </div>
                <Row className="mx-2 align-center">
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Jobs</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.jobsCount}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Subscribers</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.subsCount}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">NewsLetter</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.NWSLTR}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Podcast</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.PDCST}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Xcelian</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.XCLN}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="6" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Recruiters</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.RCRT}</span>
                      </Card>
                    </div>
                  </Col>
                  <Col xs="12" lg="11">
                    <div className="mb-3">
                      <Card className="sadmin-dash-card">
                        <span className="text-lite-grey">Campus</span>
                        <span className="dash-sadmin-fig-text">{dashboardData.CMPS}</span>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default SuperAdminDashboard;
