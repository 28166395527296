import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import AllNewsletter from './list.newsletter';
import './news.scss';
import DetailsNewsletter from './details.newsletter';
import { AllNewslettersResult } from '../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import {
  listNewsletterReq, clearListNewsletterReq, getLoginToken, editSubscriberReq,
  clearEditSubscriberReq,
} from '../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../utils/constants';
import LoaderData from '../../../utils/loader';
import Navbar from '../navbar/cx.navbar';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { SubscribeInputData } from '../../../services/sadmin/subscriber/update-subscriber/update.subscriber.types';
import { errorAlert, successAlert } from '../../../utils/alert';

export type TabProps = {
    activeTab: string;
};

export const NewsLetterComponent: React.FC = () => {
  const dispatch = useDispatch();
  const isMobileJob = window.innerWidth <= 468;
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [htmlUrl, setHtmlUrl] = React.useState('');
  const [htmlTitle, setHtmlTitle] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [recordsPerPage] = React.useState<number>(30);
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [isEditSubscribe, setIsEditSubscribe] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState('');
  const [tokenValue, setTokenValue] = React.useState('');
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const listNewsletterResponse = useSelector((state: RootState) => state.campusXcel.listNewsletter);
  const subscriberResponse = useSelector((state: RootState) => state.campusXcel.updateSubscriber);
  const [listNewsLetter, setListNewsLetter] = React.useState<AllNewslettersResult>({
    tCnt: 0,
    fCnt: 0,
    mediaData: [],
    lastEvalKey: {
      MediaTp: '',
      MediaId: '',
    },
  });
  const [subscriberInput, setSubscriberInput] = React.useState<SubscribeInputData>({
    eId: '',
    subsTp: 'NWSLTR',
  });

  const toggleResumeCloseTemplate = () => {
    setIsClickClose(false);
    setClickedIndex('');
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(getLoginToken({
      userName: '',
      passKey: '',
      isPublic: true,
      uTp: UserType.CXAPI,
      requestType: APIHeader.USER_LOGIN,
    }));
  }, []);

  React.useEffect(() => {
    if (loginTokenData.message !== '') {
      if (loginTokenData.isError) {
        setLoading(false);
      } else if (loginTokenData.token !== '') {
        setLoading(true);
        setTokenValue(loginTokenData.token);
        dispatch(listNewsletterReq({
          requestType: APIHeader.REQ_ALL_MEDIA,
          uTp: UserType.CXADM,
          limit: recordsPerPage,
          evalKey: '',
          evalTp: '',
          isFilter: 'NO',
          isSearch: 'NO',
          token: loginTokenData.token,
          mediaTp: 'NWSLTR',
          medSts: 'ACTIVE',
        }));
      }
    }
  }, [loginTokenData]);

  const fetchMoreData = () => {
    setLoading(true);
    dispatch(listNewsletterReq({
      requestType: APIHeader.REQ_ALL_MEDIA,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: evalKeyText,
      evalTp: evalTpText,
      isFilter: 'NO',
      isSearch: 'NO',
      searchBy: 'NO',
      token: tokenValue,
      mediaTp: 'NWSLTR',
      medSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (listNewsletterResponse.isError && listNewsletterResponse.message !== '') {
      setLoading(false);
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.isError, listNewsletterResponse.message]);

  React.useEffect(() => {
    if (!listNewsletterResponse.isError && listNewsletterResponse.message === 'executed') {
      setLoading(false);

      if (
        listNewsletterResponse.data
        && Object.keys(listNewsletterResponse.data).length > 0
        && Array.isArray(listNewsletterResponse.data.mediaData)
      ) {
        setListNewsLetter((prevState) => {
          // Reset if it's a search case
          const newMediaData = listNewsletterResponse.data.mediaData.filter(
            (newItem) => !prevState?.mediaData.some(
              (existingItem) => existingItem.MediaId === newItem.MediaId,
            ),
          );

          return {
            tCnt: listNewsletterResponse.data.tCnt,
            fCnt: listNewsletterResponse.data.fCnt,
            mediaData: [...(prevState?.mediaData || []), ...newMediaData], // Append filtered media data if not searching
            lastEvalKey: listNewsletterResponse.data.lastEvalKey,
          };
        });

        if (listNewsletterResponse.data.lastEvalKey !== null) {
          setEvalKeyText(listNewsletterResponse.data.lastEvalKey.MediaId);
          setEvalTpText(listNewsletterResponse.data.lastEvalKey.MediaTp);
        } else {
          setEvalKeyText('');
          setEvalTpText('');
        }
      }

      // Clear response after processing
      dispatch(clearListNewsletterReq());
    }
  }, [listNewsletterResponse.data, listNewsletterResponse.isError, listNewsletterResponse.message]);

  const handleGetNewsDetails = async (UrlHtml: string, htmlTitleDetail: string, MediaId: string) => {
    if (UrlHtml) {
      setLoading(true); // Start loader
      try {
        const response = await fetch(UrlHtml);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setHtmlUrl(text);
        setHtmlTitle(htmlTitleDetail);
        setIsClickClose(true);
        setClickedIndex(MediaId);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching news details:', error);
      } finally {
        setLoading(false); // Stop loader
      }
    }
  };

  const handleGetEmailToSubscribe = () => {
    setIsEditSubscribe(!isEditSubscribe);
  };

  const handleEditEmailChange = (e: any) => {
    setSubscriberInput({ ...subscriberInput, eId: e.target.value });
  };

  const handleAddSubscriber = () => {
    const validEmail = emailRegex.test(subscriberInput.eId);
    if (validEmail) {
      setLoading(true);
      dispatch(editSubscriberReq({
        uTp: UserType.CXAPI,
        requestType: APIHeader.REQ_ADD_SUBSCRIBER,
        inputBody: subscriberInput,
        token: tokenValue,
      }));
    } else {
      setIsValidEmail(true);
    }
  };

  React.useEffect(() => {
    if (subscriberResponse.error && subscriberResponse.message !== '') {
      setLoading(false);
      setErrorShowAlert(true);
      setIsEditSubscribe(false);
      setAlertMsg(subscriberResponse.message);
      dispatch(clearEditSubscriberReq());
    }
  }, [subscriberResponse.error, subscriberResponse.message]);

  React.useEffect(() => {
    if (!subscriberResponse.error && subscriberResponse.message !== '') {
      setLoading(false);
      setShowAlert(true);
      setIsEditSubscribe(false);
      setAlertMsg('You have successfully subscribed to the CampusXcel Newsletter');
      dispatch(clearEditSubscriberReq());
    }
  }, [subscriberResponse.error, subscriberResponse.message]);

  return (
    <div>
      {loading ? <LoaderData /> : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showAlert ? (
        successAlert(false, alertMsg, showAlert, setShowAlert)
      ) : null}
      <Navbar />
      <div className="news-bg">
        <div className="align-end mr">
          <Button onClick={handleGetEmailToSubscribe} className="public-subscribe-button">Subscribe</Button>
          {
            isEditSubscribe
              ? (
                <div className="ml-1">
                  <div className="input-wrapper-newsletter">
                    <Input
                      type="text"
                      placeholder="Enter Your Email Id"
                      value={subscriberInput.eId}
                      onChange={handleEditEmailChange}
                    />
                    <Button disabled={subscriberInput.eId === ''} type="button" onClick={handleAddSubscriber} className="campus-button-newsletter">
                      <span>ADD</span>
                    </Button>
                  </div>
                </div>
              ) : null
          }
        </div>
        {!isMobileJob
          ? (
            <Row>
              <Col lg="12">
                <div className="pt-2">
                  <Row>
                    <Col lg="7">
                      <AllNewsletter
                        clickedIndex={clickedIndex}
                        data={listNewsLetter.mediaData}
                        handleGetNewsDetails={handleGetNewsDetails}
                      />
                      <div className="align-center pt-4">
                        {listNewsLetter.mediaData.length > 0
                          ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                      </div>
                    </Col>
                    <Col lg="5">
                      {/* Advertisement Here Get info */}
                      <Offcanvas
                        isOpen={isClickClose}
                        toggle={toggleResumeCloseTemplate}
                      >
                        {htmlUrl !== ''
                          ? (
                            <div className="dash-sadmin-news-border pt-4">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null}
                      </Offcanvas>
                      {/* {
                        loading ? (
                          <div>Loading...</div>
                        ) : (
                          htmlUrl !== '' ? (
                            <div className="dash-sadmin-news-border">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null
                        )
                        } */}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <div className="pt-3">
                  <Row>
                    <Col xs="12">
                      <AllNewsletter
                        clickedIndex={clickedIndex}
                        data={listNewsLetter.mediaData}
                        handleGetNewsDetails={handleGetNewsDetails}
                      />
                      <div className="align-center pt-4">
                        {listNewsLetter.mediaData.length > 0
                          ? <Button disabled={evalKeyText === ''} onClick={fetchMoreData} className="campus-button-all">Load More...</Button> : null}
                      </div>
                    </Col>
                    <Col xs="12">
                      <Offcanvas
                        isOpen={isClickClose}
                        toggle={toggleResumeCloseTemplate}
                      >
                        {htmlUrl !== ''
                          ? (
                            <div className="dash-sadmin-news-border pt-4">
                              <DetailsNewsletter
                                htmlContent={htmlUrl}
                                htmlTitle={htmlTitle}
                              />
                            </div>
                          ) : null}
                      </Offcanvas>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default NewsLetterComponent;
