import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardImg,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf, faPhone } from '@fortawesome/free-solid-svg-icons';
import { RecruitInterviewDataResult } from '../../../../../../../../services/staffing/interviews/list-recruit-interview/list.recruit.interview.types';
import { InterviewStatusTpye } from '../../../../../../../../utils/constants';
import Modal from '../../../../../../../../utils/Modal/Modal';

interface TableProps {
  data: RecruitInterviewDataResult[];
  handleUpdateInterviewStatus: (intvCode: string, intvType: string, currentSts: string) => void;
}

// JobCard component for handling avatar logic for each card
const JobCard: React.FC<{ intvData: RecruitInterviewDataResult, handleUpdateInterviewStatus: (intvCode: string, intvType: string, currentSts: string) => void }> = ({ intvData, handleUpdateInterviewStatus }) => {
  const [avatar, setAvatar] = useState('/images/common/avatar.png');
  const [dropdowns, setDropdowns] = useState<Record<string, boolean>>({});
  const [modalIndex, setModalIndex] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string | null>(null); // State for PDF URL

  const toggleModal = (indexData: string) => {
    setOpenIndex(indexData);
    setModalIndex(indexData === modalIndex ? null : indexData);
  };

  useEffect(() => {
    if (intvData?.xcelian?.avatar && intvData.xcelian.avatar !== '') {
      const img = new Image();
      img.src = intvData.xcelian.avatar;
      img.onload = () => setAvatar(intvData.xcelian.avatar);
      img.onerror = () => setAvatar('/images/common/avatar.png');
    } else {
      setAvatar('/images/common/avatar.png'); // In case avatar is empty or undefined
    }
  }, [intvData?.xcelian?.avatar]);

  // Fetch PDF on modal open
  useEffect(() => {
    if (modalIndex === openIndex && intvData.resumeId) {
      // Check if it's a PDF
      if (intvData.resumeId.toLowerCase().includes('.pdf')) {
        // Fetch the PDF file
        fetch(intvData.resumeId)
          .then((response) => response.blob())
          .then((blob) => {
            const fileUrl = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            setPdfUrl(fileUrl); // Set the PDF URL
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.error('Error fetching the PDF:', error));
      } else {
        // Reset pdfUrl if not a PDF
        setPdfUrl(null);
      }
    }
  }, [modalIndex, openIndex, intvData.resumeId]);

  const statusIntvNameoptions = InterviewStatusTpye.map((status) => ({
    label: status.label,
    value: status.value,
  }));

  const handleShowLogin = (intvId: string) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [intvId]: !prevState[intvId],
    }));
  };

  return (
    <Col lg="6" xs="12" key={intvData.IntvId} className="mb-3">
      <Card className="recruit-card mx-1">
        <CardBody>
          <Row>
            <Col xs="4" lg="2">
              <CardImg
                alt="Xcelian Avatar"
                className="interview-logo-round"
                src={avatar}
              />
            </Col>
            <Col xs="8" lg="10">
              <Row>
                <Col xs="12"><span className="job-applicant-text">{intvData?.xcelian?.fNme || 'Unknown'} {intvData?.xcelian?.lNme || 'Unknown'}</span></Col>
                <Col xs="12"><FontAwesomeIcon icon={faEnvelope} className="form-check-job-text mx-1" /><span className="jobs-check-text">{intvData?.xcelian?.eId || 'Unknown'}</span></Col>
                <Col xs="12"><FontAwesomeIcon icon={faPhone} className="form-check-job-text mx-1" /><span className="jobs-check-text">{intvData?.xcelian?.dlCd || 'Unknown'} {intvData?.xcelian?.ph || 'Unknown'}</span></Col>
              </Row>
            </Col>
            <Col xs="12">
              <Row className="pt-4">
                <Col xs="7" lg="8">
                  <div className="align-start">
                    {intvData.resumeId !== '' ? (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          toggleModal(intvData.IntvId);
                        }}
                        className="button-icon"
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                      </Button>
                    ) : null}
                    <span className="chip-box">{intvData.crntSts}</span>
                  </div>
                </Col>
                <Col xs="5" lg="4">
                  <div className="align-end">
                    <ButtonDropdown isOpen={dropdowns[intvData.IntvId]} toggle={() => handleShowLogin(intvData.IntvId)}>
                      <DropdownToggle caret className="job-button-approve">
                        Update Status
                      </DropdownToggle>
                      <DropdownMenu>
                        {statusIntvNameoptions.map((type) => (
                          <DropdownItem key={type.value} onClick={() => handleUpdateInterviewStatus(intvData.IntvCd, intvData.IntvId, type.value)} className="my-2">
                            {type.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {modalIndex === openIndex && (
      <Modal
        show={modalIndex === openIndex}
        onClose={() => toggleModal(modalIndex)}
        disCancel={false}
        targetId={modalIndex.toString()}
        title={(
          <div className="mx-1 my-2">
            <span className="input-header-text pt-2 ml">{intvData.xcelian?.fNme} {intvData.xcelian?.lNme}</span>
          </div>
        )}
        size="XL"
      >
        {pdfUrl ? (
          <>
            <iframe
              src={pdfUrl}
              width="100%"
              height="700px"
              title="PDF Preview"
            />
            <div className="align-center mb-3 pt-2 my-4">
              <p>If the PDF doesn&apos;t load,
                <a
                  href={intvData.resumeId}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button-icon ml-1"
                >
                  Click here to open the PDF
                </a>
              </p>
            </div>
          </>
        ) : (
          // Handling for other file types or no PDF available
          <div className="align-center mb-3 pt-2 my-4">
            <p>The document cannot be previewed.<br /><br />
              <a
                href={intvData.resumeId}
                className="button-icon ml-1"
              >
                Download Word Document
              </a>
            </p>
            <div className="pt-4" />
          </div>
        )}
      </Modal>
      )}
    </Col>
  );
};

const AllJobsInterview: React.FC<TableProps> = ({ data, handleUpdateInterviewStatus }) => (
  <div>
    {data.length > 0 ? (
      <Row className="align-start margin-jobs-xcln-card">
        {data.map((intvData) => (
          <JobCard
            key={intvData.IntvId}
            intvData={intvData}
            handleUpdateInterviewStatus={handleUpdateInterviewStatus}
          />
        ))}
      </Row>
    ) : (
      <div className="table-norecords">
        <span className="table-text-no">No resumes found</span>
      </div>
    )}
  </div>
);

export default AllJobsInterview;
