import React from 'react';
import {
  Card,
  CardImg,
  Col, Row,
} from 'reactstrap';
import '../../../../recruiter.scss';
import { AddJobTypeProps } from '../../../../recruiter.type';
import { AddJobPhase1 } from './phase1.jobs';
import UpdateJobPhase2 from './phase2.jobs';
import { UpdateJobPhase3 } from './phase3.jobs';
import { UpdateJobPhase4 } from './phase4.jobs';
import UpdateJobPhase5 from './phase5.jobs';
import ReviewJobPhase6 from './phase6.review.jobs';

export const AddJobPhaseComponent: React.FC<AddJobTypeProps> = ({
  reqClientId,
  setLoadingJob,
  setErrorShowAlert,
  setAlertMsg,
  tokenData,
  activeTab,
  addJobRes,
  setAddJobRes,
  jobPhStatus,
  addNewJob,
  setAddNewJob,
  updatePh2Job,
  setUpdatePh2Job,
  // setJobPhStatus,
  apiReqTp,
  setApiReqTp,
  updatePh3Pay,
  setUpdatePh3Pay,
  updatePh4JD,
  setUpdatePh4JD,
  updatePh5Pref,
  setUpdatePh5Pref,
  previewPh6,
  setPreviewPh6,
  setLoadingHomeJob,
  setOperationTp,
}) => {
  const renderPhaseContent = () => {
    switch (jobPhStatus) {
      case 1:
        return (
          <AddJobPhase1
            reqClientId={reqClientId}
            jobPhase="phase-1"
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            addNewJob={addNewJob}
            setAddNewJob={setAddNewJob}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
          />
        );
      case 2:
        return (
          <UpdateJobPhase2
            // jobPhase="phase-2"
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            updatePh2Job={updatePh2Job}
            setUpdatePh2Job={setUpdatePh2Job}
            // setJobPhStatus={setJobPhStatus}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
          />
        );
      case 3:
        return (
          <UpdateJobPhase3
            // jobPhase="phase-2"
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            updatePh3Pay={updatePh3Pay}
            setUpdatePh3Pay={setUpdatePh3Pay}
            // setJobPhStatus={setJobPhStatus}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
          />
        );
      case 4:
        return (
          <UpdateJobPhase4
            // jobPhase="phase-2"
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            updatePh4JD={updatePh4JD}
            setUpdatePh4JD={setUpdatePh4JD}
            // setJobPhStatus={setJobPhStatus}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
          />
        );
      case 5:
        return (
          <UpdateJobPhase5
              // jobPhase="phase-2"
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            updatePh5Pref={updatePh5Pref}
            setUpdatePh5Pref={setUpdatePh5Pref}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
          />
        );
      case 6:
        return (
          <ReviewJobPhase6
              // jobPhase="phase-2"
            reqClientId={reqClientId}
            setLoadingJob={setLoadingJob}
            addJobRes={addJobRes}
            setAddJobRes={setAddJobRes}
            setErrorShowAlert={setErrorShowAlert}
            setAlertMsg={setAlertMsg}
            tokenData={tokenData}
            activeTab={activeTab}
            previewPh6={previewPh6}
            setPreviewPh6={setPreviewPh6}
            apiReqTp={apiReqTp}
            setApiReqTp={setApiReqTp}
            setLoadingHomeJob={setLoadingHomeJob}
            setOperationTp={setOperationTp}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="pt-4">
      <Row>
        <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
          <div className="align-center pt-5 recruit-job-border1 recruit-image-job-add-margin">
            <CardImg src="/images/ats/create-job.png" alt="create-job" />
          </div>
        </Col>
        <Col lg={{ size: 8, order: 2 }} xs={{ order: 1 }}>
          <Card className="job-pre-card">
            <Row>
              <Col xs="2">
                <div className="vertical-stepper">
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 1 ? 'active' : ''}`}>
                      <img src="/images/icons/job-tick.png" alt="Phase 1" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 1 ? 'text-lite-dark' : ''}`}>Basic Details</span>
                    </div>
                    <div className={`bar ${jobPhStatus >= 2 ? 'active' : ''}`} />
                  </div>
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 2 ? 'active' : ''}`}>
                      <img src="/images/icons/job-info.png" alt="Phase 2" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 2 ? 'text-lite-dark' : 'text-lite-grey'}`}>Job Info</span>
                    </div>
                    <div className={`bar ${jobPhStatus >= 3 ? 'active' : ''}`} />
                  </div>
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 3 ? 'active' : ''}`}>
                      <img src="/images/icons/job-salary.png" alt="Phase 3" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 3 ? 'text-lite-dark' : 'text-lite-grey'}`}>Pay & Benifits</span>
                    </div>
                    <div className={`bar ${jobPhStatus >= 4 ? 'active' : ''}`} />
                  </div>
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 4 ? 'active' : ''}`}>
                      <img src="/images/icons/job-details.png" alt="Phase 4" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 4 ? 'text-lite-dark' : 'text-lite-grey'}`}>Describe Job</span>
                    </div>
                    <div className={`bar ${jobPhStatus >= 5 ? 'active' : ''}`} />
                  </div>
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 5 ? 'active' : ''}`}>
                      <img src="/images/icons/job-details.png" alt="Phase 5" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 5 ? 'text-lite-dark' : 'text-lite-grey'}`}>Prefrences</span>
                    </div>
                    <div className={`bar ${jobPhStatus >= 6 ? 'active' : ''}`} />
                  </div>
                  <div className="step">
                    <div className={`circle ${jobPhStatus >= 6 ? 'active' : ''}`}>
                      <img src="/images/icons/job-preview.png" alt="Phase 6" />
                    </div>
                    <div className="text-center-aligned line-height-1">
                      <span className={`${jobPhStatus >= 6 ? 'text-lite-dark' : 'text-lite-grey'}`}>Preview</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="10">
                {renderPhaseContent()}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddJobPhaseComponent;
