import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import Select from 'react-select';
import {
  Row, Col, CardBody, Card, Input, InputGroup, Button, Form, Label, CardHeader,
  CardImg,
  NavLink,
} from 'reactstrap';
import './register.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
// import { faPerson, faPersonDress, faTransgender } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { APIHeader, UserType } from '../../../../utils/constants';
import {
  clearRegisterCMPSPostReq, getClientListReq, getLoginToken, registerCMPSReq,
} from '../../../../store/campusXcel/actions';
import { LoaderData } from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { RegisterCMPSInput } from '../../../../services/campus/register-campus/register.types';
import { AllClientsResult } from '../../../../services/sadmin/list-client/list-client.types';
import { DialCountrInfo } from '../../../../utils/MetaData/country';
import Modal from '../../../../utils/Modal/Modal';

export const RegisterCampusXcel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showNextInfo, setshowNextInfo] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [invalidField, setInvalidField] = useState(false);
  const [loaderListClient, setLoaderListClient] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  // const [activeTab, setActiveTab] = React.useState('NA');
  // const [validateEmail, setValidateEmail] = React.useState('');
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  // const [acadListData, setAcadListData] = useState<ListClientResultData[]>([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [htmlTerm, setHtmlTerm] = React.useState('');
  const loginTokenData = useSelector((state: RootState) => state.campusXcel.genToken);
  const clientListResponse = useSelector((state: RootState) => state.campusXcel.getClientList);
  const registerResponse = useSelector((state: RootState) => state.campusXcel.registerUser);
  const [clientList, setClientList] = React.useState<AllClientsResult>({
    tCnt: 0,
    fCnt: 0,
    clntData: [],
    lastEvalKey: {
      ClntTp: '',
      ClntId: '',
    },
  });
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [registerCMPSData, setRegisterCMPSData] = useState<RegisterCMPSInput>({
    fNme: '',
    lNme: '',
    dlCd: '+91',
    gnd: '',
    oGnd: '',
    ph: '',
    phTp: 'SMSPHN',
    eId: '',
    bCd: '',
    acdId: '',
    enNum: '',
    isAgreeTrm: false,
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };

  React.useEffect(() => {
    const termFile = '/campus.html';
    const fetchHtml = async () => {
      try {
        const response = await fetch(termFile);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const text = await response.text();
        setHtmlTerm(text);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch HTML file:', error);
      }
    };
    fetchHtml();
  }, []);

  React.useEffect(() => {
    if (registerResponse.error && registerResponse.message !== '') {
      dispatch(clearRegisterCMPSPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerResponse.message);
      setshowNextInfo(false);
    }
  }, [registerResponse.message, registerResponse.error]);

  React.useEffect(() => {
    if (!registerResponse.error && registerResponse.data !== '') {
      dispatch(clearRegisterCMPSPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setRegisterCMPSData({
        fNme: '',
        lNme: '',
        dlCd: '+91',
        gnd: '',
        oGnd: '',
        ph: '',
        phTp: 'SMSPHN',
        eId: '',
        bCd: '',
        acdId: '',
        enNum: '',
        isAgreeTrm: false,
      });
      if (registerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Your registration has been completed successfully. To gain access to the campusXcel platform, please contact your college administration');
      }
      setSuccessShowAlert(true);
    }
  }, [registerResponse.data, registerResponse.error]);

  React.useEffect(() => {
    dispatch(getLoginToken({
      userName: '',
      passKey: '',
      isPublic: true,
      uTp: UserType.CXAPI,
      requestType: APIHeader.USER_LOGIN,
    }));
  }, []);

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.token !== '') {
      setLoaderListClient(true);
      dispatch(getClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: 500,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        clntTp: 'CMPS',
        isMeta: true,
        token: loginTokenData.token,
        clntSts: 'ACTIVE',
      }));
    }
  }, [loginTokenData.token]);

  React.useEffect(() => {
    if (!clientListResponse.isError && clientListResponse.message === 'executed') {
      setLoaderListClient(false);
      setClientList(clientListResponse.data);
      // dispatch(clearLoginToken());
    }
  }, [clientListResponse.isError, clientListResponse.data]);

  const loginPage = () => {
    history('/campus/login');
    window.scrollTo(0, 0);
  };

  const dialNameoptions = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));
  const selectedDialNameOptions = dialNameoptions.find(
    (option) => option.value === registerCMPSData.dlCd,
  );
  const handleSelectDialData = (e: any) => {
    setRegisterCMPSData({ ...registerCMPSData, dlCd: e.value });
  };

  // const acadNameoptions = acadListData.map((acad) => ({
  //   label: acad.nme,
  //   value: acad.ClntId,
  // }));

  // const selectedAcadNameOptions = acadNameoptions.find(
  //   (option) => option.value === registerCMPSData.acdId,
  // );

  // const handleSelectAcadData = (e: any) => {
  //   setRegisterCMPSData({ ...registerCMPSData, acdId: e.value });
  // };

  const handleCheckChange = (e: any) => {
    if (e.target.checked === true) {
      setRegisterCMPSData({ ...registerCMPSData, phTp: 'SMRTPHN' });
    } else {
      setRegisterCMPSData({ ...registerCMPSData, phTp: 'SMSPHN' });
    }
  };
  const handleTermChange = (e: any) => {
    setRegisterCMPSData({ ...registerCMPSData, isAgreeTrm: e.target.checked });
  };

  const handleGenderRegister = (gender_data: string) => {
    if (gender_data === 'M') {
      setRegisterCMPSData({ ...registerCMPSData, gnd: 'M', oGnd: '' });
      // setActiveTab('M');
    } else if (gender_data === 'F') {
      setRegisterCMPSData({ ...registerCMPSData, gnd: 'F', oGnd: '' });
      // setActiveTab('F');
    } else if (gender_data === 'O') {
      setRegisterCMPSData({ ...registerCMPSData, gnd: 'O' });
      // setActiveTab('T');
    } else {
      setRegisterCMPSData({ ...registerCMPSData, gnd: 'UNK', oGnd: '' });
    }
  };
  const handleFieldChangeRegister = (e: any) => {
    const fieldName = e.target.name;
    setRegisterCMPSData({ ...registerCMPSData, [fieldName]: e.target.value });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(registerCMPSData.eId);
    const validPhone = phoneRegex.test(registerCMPSData.ph);
    if (registerCMPSData.acdId !== '' && validEmail && validPhone && loginTokenData.token !== '') {
      setRegisterLoader(true);
      dispatch(registerCMPSReq({
        inputBody: registerCMPSData,
        requestType: APIHeader.REQ_ADD_CMPS_CANDIDATE,
        uTp: UserType.CXAPI,
        token: loginTokenData.token,
      }));
    } else if (registerCMPSData.acdId === '') {
      setInvalidField(true);
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };
  const handleNext = () => {
    setshowNextInfo(true);
  };
  const handlePrevious = () => {
    setshowNextInfo(false);
  };

  const clntUserNameoptions = clientList.clntData.map((client) => ({
    label: `${client.nme}`,
    cTp: `${client.ClntTp}`,
    cId: `${client.ClntId}`,
  }));
  const selectedClientNameOptions = clntUserNameoptions && clntUserNameoptions.find(
    (option) => (option.cId === registerCMPSData.acdId),
  );

  const handleSelectCollegeData = (selectedOption: any) => {
    if (selectedOption) {
      // Do something with the selected option
      setRegisterCMPSData({
        ...registerCMPSData,
        acdId: selectedOption.cId,
      });
    }
  };

  return (
    <div className="campus-register-bg">
      {registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <NavLink href="/campus" to="/campus" className="nav-logo">
        <CardImg
          style={{
            height: '45px', width: 'auto',
          }}
          src="/campusXcel.png"
          alt="campusXcelLogo"
        />
      </NavLink>
      <div className="my-1">
        <div className="align-center mx-3">
          <span className="form-header-text">
            Register for Campus
          </span>
        </div>
        <Row className="align-center">
          {!showNextInfo
            ? (
              <Col lg={{ size: 4, order: 1 }} xs={{ order: 2 }}>
                <div className="mx-5 pt-3">
                  <CardImg
                    src="/images/campus/register-campus.png"
                    alt="menu-logo"
                    className="register-campus-image"
                  />
                </div>
              </Col>
            )
            : (
              <Col lg={{ size: 5, order: 1 }} xs={{ order: 2 }}>
                <div className="mx-5">
                  <CardImg
                    src="/images/campus/register-campus-1.png"
                    alt="menu-logo"
                    className="register-campus-image"
                  />
                </div>
              </Col>
            )}
          <Col lg={{ size: 6, order: 2 }} xs={{ order: 1 }}>
            <Form onSubmit={handleSubmit}>
              {!showNextInfo
                ? (
                  <div className="pt-4">
                    <Card className="register-card">
                      <CardHeader className="mandate-star">
                        Campus Info
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="7" className="pt-4">
                            <Select
                              value={registerCMPSData.acdId === '' ? null : selectedClientNameOptions}
                              options={clntUserNameoptions}
                              onChange={handleSelectCollegeData}
                              styles={{
                                control: (base: any) => ({
                                  ...base,
                                  '&:hover': { borderColor: '#8B0000' },
                                  border: '1px solid #DDE0E4',
                                  minHeight: '50px',
                                  borderRadius: '10px',
                                  padding: 1,
                                  textAlign: 'left',
                                  fontSize: '15px',
                                  fontWeight: 400,
                                  color: '#575656',
                                  boxShadow: 'none',
                                  backgroundColor: '#fff',
                                  '&:focus': {
                                    borderColor: '#E1EDF8',
                                    boxShadow: 'none',
                                    color: '#575656',
                                    background: '#fff',
                                  },
                                }),
                                option: (provided: any, state: { isSelected: any; }) => ({
                                  ...provided,
                                  color: state.isSelected ? '#383838' : '#212121',
                                  padding: 15,
                                  textAlign: 'left',
                                  background: state.isSelected ? '#FAFCFB' : '#fff',
                                  '&:hover': {
                                    background: '#FAFCFB',
                                  },
                                }),
                              }}
                              noOptionsMessage={() => 'No College Found'}
                              placeholder="Select College..."
                              isLoading={loaderListClient}
                            />
                          </Col>
                          <Col lg="5" className="pt-4">
                            <Input
                              placeholder="Enter Campus Code"
                              type="text"
                              required
                              name="bCd"
                              value={registerCMPSData.bCd}
                              disabled={registerLoader}
                              className="campus-input"
                              onChange={handleFieldChangeRegister}
                            />
                          </Col>
                          <Col lg="7" className="pt-4">
                            <Input
                              placeholder="Enter your Enrollment Number"
                              type="text"
                              required
                              name="enNum"
                              value={registerCMPSData.enNum}
                              disabled={registerLoader}
                              onChange={handleFieldChangeRegister}
                              className="campus-input"
                            />
                          </Col>
                          <Col lg="5" className="pt-4">
                            <div className="align-end pt-2">
                              <Button disabled={registerCMPSData.acdId === '' || registerCMPSData.bCd === '' || registerCMPSData.enNum === ''} className="button-icon" onClick={handleNext}>
                                Next <FontAwesomeIcon icon={faArrowRight} />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                )
                : (
                  <div className="pt-3">
                    <Card className="register-card">
                      <CardHeader className="register-card-header-footer">
                        <Row>
                          <Col lg="7" xs="8">
                            <div className="align-start">
                              Personal Info
                            </div>
                          </Col>
                          <Col lg="5" xs="4">
                            <div className="align-end">
                              <Button className="button-icon" onClick={handlePrevious}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col lg="6" className="pt-2">
                            <Input
                              placeholder="Enter your First Name"
                              type="text"
                              required
                              name="fNme"
                              value={registerCMPSData.fNme}
                              disabled={registerLoader}
                              onChange={handleFieldChangeRegister}
                              className="campus-input"
                            />
                          </Col>
                          <Col lg="6" className="pt-2">
                            <Input
                              placeholder="Enter your Last Name"
                              type="text"
                              required
                              name="lNme"
                              value={registerCMPSData.lNme}
                              disabled={registerLoader}
                              onChange={handleFieldChangeRegister}
                              className="campus-input"
                            />
                          </Col>
                          <Col lg="12" className="pt-3">
                            <Input
                              placeholder="Enter your Email Id"
                              type="text"
                              required
                              name="eId"
                              value={registerCMPSData.eId}
                              disabled={registerLoader}
                              onChange={handleFieldChangeRegister}
                              className="campus-input"
                            />
                            <span className="text-lite-grey align-start pt-1 ml">
                              UserId and for all communications
                            </span>
                          </Col>
                          <Col lg="12" className="pt-3">
                            <InputGroup>
                              <Select
                                value={registerCMPSData.dlCd === '' ? null : selectedDialNameOptions}
                                defaultInputValue="+91"
                                options={dialNameoptions}
                                onChange={handleSelectDialData}
                                styles={{
                                  control: (base: any) => ({
                                    ...base,
                                    '&:hover': { borderColor: '#8B0000' },
                                    border: '1px solid #DDE0E4',
                                    minHeight: '50px',
                                    borderRadius: '10px',
                                    padding: 1,
                                    fontSize: '15px',
                                    fontWeight: 400,
                                    color: '#575656',
                                    boxShadow: 'none',
                                    backgroundColor: '#fff',
                                    '&:focus': {
                                      borderColor: '#E1EDF8',
                                      boxShadow: 'none',
                                      color: '#575656',
                                      background: '#fff',
                                    },
                                  }),
                                  option: (provided: any, state: { isSelected: any; }) => ({
                                    ...provided,
                                    color: state.isSelected ? '#383838' : '#212121',
                                    padding: 15,
                                    textAlign: 'left',
                                    // border: '1px solid #F9CB9C',
                                    background: state.isSelected ? '#FAFCFB' : '#fff',
                                    '&:hover': {
                                      background: '#FAFCFB',
                                    },
                                  }),
                                }}
                                noOptionsMessage={() => 'No Dial Code Found'}
                              />
                              <Input
                                placeholder="Enter your Phone Number"
                                type="number"
                                required
                                name="ph"
                                value={registerCMPSData.ph}
                                disabled={registerLoader}
                                onChange={handleFieldChangeRegister}
                                className="campus-input mx-1"
                              />
                            </InputGroup>
                            <span className="text-lite-grey align-start pt-1 ml">Use same number for<span className="text-whatsapp mx-1">WhatsApp</span>services</span>
                          </Col>
                          <Col lg="12" className="pt-3">
                            <Row className="bg-gender-register mx-1">
                              <Col xs="12">
                                <div className="mb-1">
                                  <span className="text-lite">Gender Details</span>
                                </div>
                              </Col>
                              <Col lg="2" xs="6">
                                <Input
                                  type="radio"
                                  name="gender"
                                  required
                                  checked={registerCMPSData.gnd === 'M'}
                                  className="campus-radio-box cursor-pointer"
                                  onChange={() => handleGenderRegister('M')}
                                />
                                <Label check className="form-check-text mx-1">Male</Label>
                              </Col>
                              <Col lg="3" xs="6">
                                <Input
                                  type="radio"
                                  name="gender"
                                  required
                                  checked={registerCMPSData.gnd === 'F'}
                                  className="campus-radio-box cursor-pointer"
                                  onChange={() => handleGenderRegister('F')}
                                />
                                <Label check className="form-check-text mx-1">Female</Label>
                              </Col>
                              <Col lg="3" xs="6">
                                <Input
                                  type="radio"
                                  name="gender"
                                  required
                                  checked={registerCMPSData.gnd === 'O'}
                                  className="campus-radio-box cursor-pointer"
                                  onChange={() => handleGenderRegister('O')}
                                />
                                <Label check className="form-check-text mx-1">Others</Label>
                              </Col>
                              <Col lg="4" xs="6">
                                <Input
                                  type="radio"
                                  name="gender"
                                  required
                                  checked={registerCMPSData.gnd === 'UNK'}
                                  className="campus-radio-box cursor-pointer"
                                  onChange={() => handleGenderRegister('UNK')}
                                />
                                <Label check className="form-check-text mx-1">Prefer Not to Say</Label>
                              </Col>
                              {registerCMPSData.gnd === 'O' ? (
                                <Col xs="12" lg="10">
                                  <div className="my-2 pt-2 align-end">
                                    <Input
                                      placeholder="Enter your Gender"
                                      type="text"
                                      required
                                      name="oGnd"
                                      value={registerCMPSData.oGnd}
                                      disabled={registerLoader}
                                      onChange={handleFieldChangeRegister}
                                      className="campus-input mx-1"
                                    />
                                  </div>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                          <Col lg="12" className="pt-3 ml">
                            <div className="align-start">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  disabled={registerLoader}
                                  onChange={handleCheckChange}
                                  checked={registerCMPSData.phTp === 'SMRTPHN'}
                                  className="campus-check-box ml cursor-pointer"
                                />{' '}
                                <span className="form-check-text">Send me important updates on my </span><span className="text-whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="campus-icon-green mx-1" /> WhatsApp</span>
                              </Label>
                            </div>
                          </Col>
                          <Col lg="12" className="pt-3 ml">
                            <div className="align-start">
                              <Label check>
                                <Input
                                  type="checkbox"
                                  disabled={registerLoader}
                                  onChange={handleTermChange}
                                  checked={registerCMPSData.isAgreeTrm}
                                  className="campus-check-box ml pt-2 cursor-pointer"
                                />{' '}
                                <span className="text-lite-grey">I have read the <Button className="button-transparent" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
                              </Label>
                            </div>
                          </Col>
                          <Col lg="12" className="pt-4">
                            <div className="mx-2">
                              <Button className="campus-button mx-1" disabled={!registerCMPSData.isAgreeTrm || ((registerCMPSData.gnd === 'O' && registerCMPSData.oGnd === '') || registerCMPSData.gnd === '')}>
                                Register
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                )}
            </Form>
            <div className="align-end pt-2">
              <span className="mx-3 text-lite-grey">Already signed up ? <Button className="button-transparent" onClick={loginPage}>Login </Button></span>
            </div>
          </Col>
        </Row>
        <Modal
          show={modalConfirm || false}
          onClose={() => (false)}
          disCancel
          targetId="termCond"
          title=""
          size="XL"
        >

          <div
            className="mx-5"
                  // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: htmlTerm }}
          />
          <div className="align-center my-3">
            <Button className="campus-button-all mx-1" onClick={handleTermUse}>
              Accept
            </Button>
          </div>
        </Modal>
      </div>
      <div className="pt-4" />
    </div>
  );
};
export default RegisterCampusXcel;
