import React from 'react';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col, Row,
} from 'reactstrap';
import '../dashboard.scss';
import { faCircleDot } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { ListNewsletterResultData } from '../../../../../services/sadmin/newsletter/get-newsletter/list-newsletter.types';
import { formatUserDate } from '../../../../../utils/constants';

  interface TableProps {
    clickedIndex: string;
    data: ListNewsletterResultData[];
    handleGetNewsDetails: (UrlHtml: string, htmlTitleDetail: string, MediaId: string) => void;
    handleUpdateMediaStatus: (mediaId: string, mediaTp: string, MediaSts: string) => void;
  }

const AllNewsletter: React.FC<TableProps> = ({
  data,
  clickedIndex,
  handleGetNewsDetails,
  handleUpdateMediaStatus,
}) => (
  <div className="">
    {data.length > 0
      ? (
        <div>
          <Row className="align-start margin-jobs-xcln-card">
            {data.map((newsData, index) => (
              <Col lg="12 my-1" xs="12 my-1" key={`job-info${index.toString()}`}>
                <Card className="sadmin-news-card">
                  <CardBody className="padding-zero">
                    <div className="new-layout-newsletter">
                      <Row className="align-items-center"> {/* This helps align items vertically */}
                        <Col lg={{ size: 4, order: 2 }} xs={{ order: 2 }}>
                          <div className="align-center">
                            <CardImg
                              alt="..."
                              src={newsData.previewUrl}
                              className="newsletter-img"
                            />
                          </div>
                        </Col>
                        <Col lg={{ size: 8, order: 1 }} xs={{ order: 1 }}>
                          <div className="align-text-justify">
                            <Button disabled={clickedIndex === newsData.MediaId} onClick={() => handleGetNewsDetails(newsData.descHtmlUrl, newsData.subTtl, newsData.MediaId)} className="button-sadmin-newsletter-header">
                              {newsData.subTtl}
                            </Button>
                          </div>
                          <div className="align-start">
                            <span className="text-lite">
                              {newsData.author.fNme} {newsData.author.lNme}
                            </span>
                            <span className="text-lite mx-2">
                              |
                            </span>
                            <span className="text-lite">
                              {formatUserDate(newsData.updtOn)}
                            </span>
                          </div>
                          <div className="align-text-justify pt-3">
                            <span className="dash-sadmin-news-description">
                              {newsData.subLine}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row className="pt-3">
                      <Col xs="5" lg="5">
                        <div className="align-start pt-2">
                          {newsData.medSts === 'PENDING'
                            ? <span className="icon-grey">PENDING</span>
                            : newsData.medSts === 'ACTIVE'
                              ? <span className="correct-pass-key"><FontAwesomeIcon icon={faCircleDot} /> LIVE</span>
                              : newsData.medSts === 'BLOCKED'
                                ? <span className="wrong-pass-key"><FontAwesomeIcon icon={faBan} /> BLOCKED</span> : null}
                        </div>
                      </Col>
                      <Col xs="7" lg="7">
                        <div className="align-end">
                          {newsData.medSts === 'PENDING'
                            ? (
                              <>
                                <Button onClick={() => handleUpdateMediaStatus(newsData.MediaId, newsData.MediaTp, 'BLOCKED')} className="button-icon mr">
                                  BLOCK
                                </Button>
                                <Button onClick={() => handleUpdateMediaStatus(newsData.MediaId, newsData.MediaTp, 'ACTIVE')} className="campus-button-all">
                                  APPROVE
                                </Button>
                              </>
                            )
                            : newsData.medSts === 'ACTIVE'
                              ? (
                                <Button onClick={() => handleUpdateMediaStatus(newsData.MediaId, newsData.MediaTp, 'BLOCKED')} className="button-icon">
                                  BLOCK
                                </Button>
                              )
                              : newsData.medSts === 'BLOCKED'
                                ? (
                                  <Button onClick={() => handleUpdateMediaStatus(newsData.MediaId, newsData.MediaTp, 'ACTIVE')} className="campus-button-all">
                                    APPROVE
                                  </Button>
                                ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div className="table-norecords">
          <span className="table-text-no">No Newsletter Found</span>
        </div>
      )}
  </div>
);

export default AllNewsletter;
