import React, { } from 'react';
import {
  Page, Text, View, Document, StyleSheet, Image,
} from '@react-pdf/renderer';
import { PreviewResumeProps } from '../resume.template.type';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 15,
    textAlign: 'left',
    fontWeight: 500,
    color: '#091d29',
  },
  subheaderDesg: {
    fontSize: 13,
    fontWeight: 400,
    color: '#091d29',
  },
  subheader: {
    fontSize: 11,
    textAlign: 'left',
    fontWeight: 'medium',
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
  },
  icon: {
    fontSize: 12,
    color: '#187C98',
  },
  headertext: {
    fontSize: 11,
    fontWeight: 500,
    color: '#595555',
    paddingTop: 10,
    marginRight: 10,
  },
  subheadertext: {
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 500,
    // color: '#187C98',
    color: '#595555',
    marginLeft: 10,
  },
  subheadertextcontent: {
    fontSize: 10,
    textAlign: 'left',
    fontWeight: 500,
    // color: '#187C98',
    color: '#595555',
    marginLeft: 10,
  },
  marginbottom: {
    marginBottom: 10,
  },
  iconsubheader: {
    color: '#187C98',
    fontSize: 12,
  },
  newcolortext: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'medium',
    color: '#dc6d05',
  },
  profilePicture: {
    width: 40,
    height: 40,
    borderRadius: 50,
    alignSelf: 'center',
  },
  border: {
    color: '#5898aa',
  },
  borderblue: {
    borderBottom: '1px solid #c6d7db',
    paddingBottom: 10,
  },
  iconmargin: {
    marginLeft: 10,
  },
  imageicon: {
    width: 10,
    height: 10,
  },
});

export const PDFTemplate1: React.FC<PreviewResumeProps> = ({ previewResume, myProfile }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>{myProfile.fNme} {myProfile.lNme}</Text>
        <Text style={styles.marginbottom} />
        <Text style={styles.headertext}>
          <Image
            style={styles.imageicon}
            src="/images/resumes/template-icons-1/envelope.jpg"
          /> {' '}{myProfile.cEId}
        </Text>
        <Text style={styles.headertext}>
          <Image
            style={styles.imageicon}
            src="/images/resumes/template-icons-1/phone-call.jpg"
          />
          {' '}{myProfile.dlCd}{' '}{myProfile.cPh}
        </Text>
        <Text style={styles.subheaderDesg}>{previewResume.desg}</Text>
      </View>
    </Page>
  </Document>
);
export default PDFTemplate1;
