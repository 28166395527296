import React from 'react';
import {
  Button, CardImg, Col, Input, Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  faArrowLeft, faMagnifyingGlass, faPlus, faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminRecruitListClientsTable from './list.clients';
import { successAlert, errorAlert } from '../../../../../../utils/alert';
import LoaderData from '../../../../../../utils/loader';
import { AdminStaffAddClients } from './add.clients';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../../utils/service/localstorage-service';
import { APIHeader, UserType } from '../../../../../../utils/constants';
import { getStaffClientListReq } from '../../../../../../store/campusXcel/actions';
import { AllStaffClientsResult, DelStaffClientData, ListStaffClientResultData } from '../../../../../../services/staffing/clients/list-clients/list-client.types';

export const AdminRecruitClients: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const userProfile = getUserProfileLocalStorage();
  const [reqClientId, setReqClientId] = React.useState('');
  const [operationTp, setOperationTp] = React.useState('list-client');
  const [loaderClient, setLoaderClient] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const staffClientListResponse = useSelector((state: RootState) => state.campusXcel.getStaffClientList);
  const [, setAllStaffCientData] = React.useState<AllStaffClientsResult>({
    tCnt: 0,
    fCnt: 0,
    clntData: [],
    lastEvalKey: {
      ClntCd: '',
      ClntId: '',
    },
  });
  const [, setDeleteRecord] = React.useState<DelStaffClientData>({
    ClntCd: '',
    ClntId: '',
    nme: '',
  });
  const [, setEditRecord] = React.useState<ListStaffClientResultData>({
    ClntCd: '',
    ClntId: '',
    nme: '',
    cntEId: '',
    dlCd1: '',
    cntPh1: '',
    citySt: '',
    dlCd2: '',
    cntPh2: '',
    txId: '',
    web: '',
    logoUrl: '',
  });

  const staffClientColumns = [
    { header: 'Logo', accessor: '' },
    { header: 'Client ID', accessor: 'ClntId' },
    { header: 'Name', accessor: '' },
    { header: 'Email', accessor: 'cntEId' },
    { header: 'Phone1', accessor: '' },
    { header: 'Phone2', accessor: '' },
    { header: 'Location', accessor: 'citySt' },
    { header: 'Action', accessor: '' },
  ];

  React.useEffect(() => {
    setReqClientId(userProfile && JSON.parse(userProfile).profileData.rcrtCd);
  }, [userProfile && JSON.parse(userProfile).profileData.rcrtCd]);

  const handleAddNewClient = () => {
    setOperationTp('add-client');
  };
  const handleShowListClient = () => {
    setOperationTp('list-client');
  };

  React.useEffect(() => {
    if (operationTp === 'list-client' && reqClientId !== '') {
      setLoadingMore(true);
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [operationTp, reqClientId]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getStaffClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        clntTp: reqClientId,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getStaffClientListReq({
          requestType: APIHeader.REQ_ALL_CLIENT,
          uTp: UserType.CXADM,
          limit: recordsPerPage, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          clntTp: reqClientId,
          isMeta: false,
          clntSts: 'ACTIVE',
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getStaffClientListReq({
      requestType: APIHeader.REQ_ALL_CLIENT,
      uTp: UserType.RCRT,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      clntTp: reqClientId,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getStaffClientListReq({
      requestType: APIHeader.REQ_ALL_CLIENT,
      uTp: UserType.RCRT,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      clntTp: reqClientId,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (staffClientListResponse.isError && staffClientListResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(staffClientListResponse.message);
      setErrorShowAlert(true);
    }
  }, [staffClientListResponse.isError, staffClientListResponse.message]);

  React.useEffect(() => {
    if (!staffClientListResponse.isError && staffClientListResponse.message === 'executed') {
      setLoadingMore(false);

      if (searchText !== '') {
        setPagedData({ [currentPage]: staffClientListResponse.data.clntData });
      } else if (
        staffClientListResponse.data
        && Object.keys(staffClientListResponse.data).length > 0
        && Array.isArray(staffClientListResponse.data.clntData)
      ) {
        const newPageData = staffClientListResponse.data.clntData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllStaffCientData({
          tCnt: staffClientListResponse.data.tCnt,
          fCnt: staffClientListResponse.data.fCnt,
          clntData: staffClientListResponse.data.clntData,
          lastEvalKey: staffClientListResponse.data.lastEvalKey,
        });
        if (staffClientListResponse.data.lastEvalKey !== null) {
          setEvalKeyText(staffClientListResponse.data.lastEvalKey.ClntId);
          setEvalTpText(staffClientListResponse.data.lastEvalKey.ClntCd);
        }
        setTotalRecords(staffClientListResponse.data.tCnt);
      }
    }
  }, [staffClientListResponse]);

  return (
    <div>
      { loaderClient ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml my-2">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/org.png" alt="batches" />
        <span className="text-lite-grey ml">MY CLIENTS</span>
      </div>
      <div className="pt-1">
        <div className="align-end mr-1">
          {operationTp === 'list-client' ? (
            <Button className="button-transparent align-center" onClick={handleAddNewClient}>
              <FontAwesomeIcon icon={faPlus} className="mr" />
              Add New
            </Button>
          ) : (
            <Button className="button-transparent align-center" onClick={handleShowListClient}>
              <FontAwesomeIcon icon={faArrowLeft} className="mr" />
              Go Back
            </Button>
          )}
        </div>
        <hr className="mx-2 hr-dasshed-lite" />
        {operationTp === 'list-client'
          ? (loadingMore ? (
            <div className="loader-campusxcel align-center1 pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col xs="12" lg="5">
                <div className="align-end">
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <Button type="button" onClick={handleSearchProjects}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                  <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </div>
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  {pagedData[currentPage]
                    ? (
                      <AdminRecruitListClientsTable
                        columns={staffClientColumns}
                        data={pagedData[currentPage]}
                        currentPage={currentPage}
                        recordsPerPage={recordsPerPage}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                        setEditRecord={setEditRecord}
                        setDeleteRecord={setDeleteRecord}
                      />
                    ) : null}
                </div>
              </Col>
            </Row>
          ))
          : (
            <AdminStaffAddClients
              setOperationTp={setOperationTp}
              setAlertMsg={setAlertMsg}
              setLoaderClient={setLoaderClient}
              setSuccessShowAlert={setSuccessShowAlert}
              setErrorShowAlert={setErrorShowAlert}
              operationTp={operationTp}
              tokenData={tokenData}
              client_id={reqClientId}
            />
          )}
      </div>
      <div className="mb-3 p-4" />
    </div>
  );
};

export default AdminRecruitClients;
