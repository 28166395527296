import React from 'react';
import {
  Button,
  CardImg, Col, NavLink, Row,
} from 'reactstrap';
import '../xcelian.scss';
import XcelianResumeInput from './resume-input/resume.input';
import { PreviewTemplate1 } from './template/template1/template1'; // Add more templates as necessary
import { XclnResumeInput } from '../../../../services/staffing/resume-preview/add-resume-info/update.resume.info.types';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../utils/service/localstorage-service';
import Accordion from '../../../../utils/Accordion/Accordion';
import Offcanvas from '../../../../utils/OffCanvas/OffcanvasComponent';

export const XcelianResumeComponent: React.FC = () => {
  const tokenData = getTokenFromLocalStorage();
  const isMobileJob = window.innerWidth <= 468;
  const userProfile = getUserProfileLocalStorage();
  const [isClickClose, setIsClickClose] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('template1');
  const [myProfile, setMyProfile] = React.useState({
    UserCd: '',
    UserTp: '',
    cEId: '',
    fNme: '',
    lNme: '',
    dlCd: '+91',
    cPh: '',
  });
  const [previewResume, setPreviewResume] = React.useState<XclnResumeInput>({
    XclnCd: '',
    XclnId: '',
    skills: [],
    oSkills: [],
    desg: '',
    isAbled: '',
    isFemale: '',
    amnt: '',
    amntTp: '',
    expR1: 0,
    expR2: 0,
  });

  const toggleResumeTemplate = () => {
    setIsClickClose(!isClickClose);
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId !== '') {
      const { profileData } = JSON.parse(userProfile);
      setMyProfile({
        UserTp: profileData.UserTp,
        UserCd: profileData.UserCd,
        fNme: profileData.fNme,
        lNme: profileData.lNme,
        cEId: profileData.eId,
        dlCd: profileData.dlCd,
        cPh: profileData.ph,
      });
    }
  }, [userProfile]);

  const handleUpdateResumeData = (event: any) => {
    setPreviewResume({ ...previewResume, [event.target.name]: event.target.value });
  };

  // Define available templates
  const templates = [
    { id: 'template1', component: PreviewTemplate1, imgSrc: '/images/resumes/template-icons-1/template-1.png' },
    // Add more templates here
  ];

  return (
    <div>
      <div className="allign-start ml my-2">
        <CardImg className="recruit-main-header-img-card" src="/images/icons/resume.png" alt="batches" />
        <span className="text-lite-grey ml">My Resume</span>
      </div>
      {!isMobileJob ? (
        <Row className="pt-3">
          <Col lg="4">
            <div className="xcelian-right-border">
              <XcelianResumeInput
                handleUpdateResumeData={handleUpdateResumeData}
                previewResume={previewResume}
                tokenData={tokenData}
                myProfile={myProfile}
              />
            </div>
          </Col>
          <Col lg="6">
            {templates.map((template) => activeTab === template.id && (
            <template.component
              key={template.id}
              previewResume={previewResume}
              myProfile={myProfile}
            />
            ))}
          </Col>
          <Col lg="2">
            <div className="xcelian-left-border">
              <div className="align-center">
                <span className="text-lite">All Templates</span>
              </div>
              <div className="my-3 align-center template-design">
                {templates.map((template) => (
                  <NavLink
                    key={template.id}
                    className="cursor-pointer"
                    title={template.id}
                    active={activeTab === template.id}
                    onClick={() => setActiveTab(template.id)}
                  >
                    <CardImg src={template.imgSrc} alt="" className="preview-resume-image" />
                  </NavLink>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="my-1 pt-3">
          <Col xs="12">
            <div className="align-start">
              <Button className="button-icon" onClick={toggleResumeTemplate}>
                <CardImg className="recruit-main-header-img-card-none" src="/images/icons/resume-pdf.png" alt="batches" />
                <span className="text-lite-grey ml">Templates</span>
              </Button>
              <Offcanvas
                isOpen={isClickClose}
                toggle={toggleResumeTemplate}
              >
                <div className="ml-1">All Templates</div>
                <div className="my-3 align-center template-design">
                  {templates.map((template) => (
                    <NavLink
                      key={template.id}
                      className="cursor-pointer"
                      title={template.id}
                      active={activeTab === template.id}
                      onClick={() => setActiveTab(template.id)}
                    >
                      <CardImg src={template.imgSrc} alt="" className="preview-resume-image" />
                    </NavLink>
                  ))}
                </div>
              </Offcanvas>
            </div>
          </Col>
          <Col xs="12">
            <Accordion
              title="Input Resume"
              defaultOpen={false}
              targetId="myResume"
            >
              <div className="xcelian-right-border">
                <XcelianResumeInput
                  handleUpdateResumeData={handleUpdateResumeData}
                  previewResume={previewResume}
                  tokenData={tokenData}
                  myProfile={myProfile}
                />
              </div>
            </Accordion>
          </Col>
          <Col xs="12 pt-3">
            {templates.map((template) => activeTab === template.id && (
            <template.component
              key={template.id}
              previewResume={previewResume}
              myProfile={myProfile}
            />
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default XcelianResumeComponent;
