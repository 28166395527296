import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearAddClientReq,
  clearAddClientUserReq,
  clearAddNewJobReq,
  clearAddStaffClientReq,
  clearBatchReq,
  clearCandidatesListReq,
  clearCandStsReq,
  clearCert,
  clearCommentListReq,
  clearCommentReq,
  clearContactUsPostReq,
  clearDelClientReq,
  clearEditJobsReq,
  clearEditNewsletterReq,
  clearEditNewsletterStatusReq,
  clearEditSubscriberReq,
  clearFollowReq,
  clearGetClientReq,
  clearGetClientUserReq,
  clearGetS3CxMetaReq,
  clearGetS3FilesReq,
  clearGetSadminDashReq,
  clearGetStaffClientReq,
  clearGetStaffDashReq,
  clearHobbies,
  clearInterviewStatus,
  clearJobByDetailsReq,
  clearjobByIdReq,
  clearJobStatusReq,
  clearLikeReq,
  clearListJobsByStatusReq,
  clearListMetaJobReq,
  clearListMETAReq,
  clearListNewsletterReq,
  clearListPrivateJobReq,
  clearListPublicJobReq,
  clearListRecruitInterviewReq,
  clearListXcelianJobReq,
  clearLoginToken,
  clearMentorAccess,
  clearMentorStatusListReq,
  clearMetaPinCodeReq,
  clearObjective,
  clearOrgProject,
  clearPassKeyReq,
  clearPostDBReq,
  clearPostListReq,
  clearPostPublish,
  clearProfileReq,
  clearRecruitInterviewStatus,
  clearRegisterAbtMeMNTRReq,
  clearRegisterCMPSPostReq,
  clearRegisterEmailMNTRReq,
  clearRegisterEmployMNTRReq,
  clearRegisterPrsnlMNTRReq,
  clearRegisterXCLNPostReq,
  clearResultCert,
  clearResultHobbies,
  clearResumeDataObj,
  clearResumeLoad,
  clearResumeParser,
  clearResumeProfile,
  clearResumeVideo,
  clearUpdateS3CxMetaReq,
  clearUploadS3FilesReq,
  clearUserLikesListReq,
  clearUserProfilePutReq,
  clearVideo,
  clearXclnResumeFilePutReq,
  clearXclnResumePutReq,
  getUpdateS3CxMetaReq,
  updateAddClientReq,
  updateAddClientUserReq,
  updateAddNewJobReq,
  updateAddStaffClientReq,
  updateBatchListReq,
  updateBatchReq,
  updateCandidatesListReq,
  updateCandStsReq,
  updateCert,
  updateClientListReq,
  updateClientUserListReq,
  updateCommentListReq,
  updateCommentReq,
  updateContactUsPostReq,
  updateData,
  updateDelClientReq,
  updateDelOrgProjReq,
  updateDelPostPublish,
  updateEditJobsReq,
  updateEditNewsletterReq,
  updateEditNewsletterStatusReq,
  updateEditSubscriberReq,
  updateFileURLReq,
  updateFollowReq,
  updateForgotPassword,
  updateGetS3FilesReq,
  updateGetSadminDashReq,
  updateGetStaffDashReq,
  updateHobbies,
  updateInterviewStatus,
  updateJobByDetailsReq,
  updatejobByIdReq,
  updateJobStatusReq,
  updateLikeReq,
  updateListJobsByStatusReq,
  updateListMetaJobReq,
  updateListMETAReq,
  updateListNewsletterReq,
  updateListOrgProj,
  updateListPrivateJobReq,
  updateListPublicJobReq,
  updateListRecruitInterviewReq,
  updateListXcelianJobReq,
  updateMentorAccess,
  updateMentorStatusListReq,
  updateMetaPinCodeReq,
  updateObjective,
  updateOrgProject,
  updatePassKeyReq,
  updatePostDBReq,
  updatePostListReq,
  updatePostPublish,
  updatePreviewResume,
  updateProfile,
  updateRecruitInterviewStatus,
  updateRegisterAbtMeMNTRReq,
  updateRegisterCMPSPostReq,
  updateRegisterEmailMNTRReq,
  updateRegisterEmployMNTRReq,
  updateRegisterPrsnlMNTRReq,
  updateRegisterXCLNPostReq,
  updateResultCert,
  updateResultHobbies,
  updateResumeDataObj,
  updateResumeLoad,
  updateResumeParser,
  updateResumeProfile,
  updateResumeVideo,
  updateStaffClientListReq,
  updateToken,
  updateUpdateS3CxMetaReq,
  updateUploadS3FilesReq,
  updateUserLikesListReq,
  updateUserProfilePutReq,
  updateVideo,
  updateXclnResumeFilePutReq,
  updateXclnResumePutReq,
} from './actions';
import * as actions from './actions';
import { CampusXcelAppState } from './action.types';

export const initialState: CampusXcelAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  registerUser: {
    message: '',
    data: '',
    error: false,
  },
  getClientList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      clntData: [],
      lastEvalKey: {
        ClntTp: '',
        ClntId: '',
      },
    },
    isError: false,
    message: '',
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  getProfileData: {
    isError: false,
    message: '',
    profileData: {
      fNme: '',
      lNme: '',
      ph: '',
      eId: '',
      avtr: '',
      gnd: '',
      dlCd: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      isAdm: false,
      cmpsTp: '',
      cmpsCd: '',
      cmpsNme: '',
      cmpsCrs: {
        crs: '',
        strm: '',
        sDte: '',
        eDte: '',
      },
      rcrtCd: '',
      rcrtNme: '',
      rcrtTp: '',
    },
  },
  forgotPassKeyData: {
    forgotPasswordData: '',
    message: '',
    isError: false,
  },
  updateFileURL: {
    url: '',
    isError: false,
    message: '',
  },
  resumeProfileData: {
    perData: {
      currLoc: '',
      cEId: '',
      diffAbl: false,
      cnty: '',
      diffAblRsn: '',
      addr: '',
      pin: '',
      cty: '',
      st: '',
      langKnwn: [],
      isPrefPAN: false,
      dOB: '',
      dlCd: '',
      cPh: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
    },
    isError: false,
    message: '',
  },
  updateUserProfile: {
    message: '',
    errorMessage: '',
    error: false,
  },
  updatePassKey: {
    message: '',
    error: false,
  },
  uploadS3Files: {
    fileName: '',
  },
  updateS3CxMeta: {
    success: false,
    message: '',
  },
  getS3CxMeta: {
    success: false,
    data: [],
    message: '',
  },
  getS3Files: {
    data: [],
    identifierTp: '',
  },
  getObjectiveResume: {
    obj: '',
    message: '',
    isError: false,
  },
  updateObjectiveResume: {
    message: '',
    error: false,
  },
  getVideoResume: {
    video: '',
    message: '',
    isError: false,
  },
  updateVideoResume: {
    message: '',
    error: false,
  },
  updateOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  listOrgProj: {
    orgProj: [],
    isError: false,
    message: '',
  },
  deleteOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  previewResume: {
    allData: {
      currLoc: '',
      cEId: '',
      btCd: '',
      addr: '',
      diffAbl: false,
      cnty: '',
      langKnwn: [],
      updtOn: '',
      orgProj: [],
      CandCd: '',
      diffAblRsn: '',
      isPrefPAN: false,
      obj: '',
      CandId: '',
      dOB: '',
      dlCd: '',
      cPh: '',
      cPhTp: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
      edu: {
        acad: '',
        strm: '',
        crsTp: '',
        sDte: '',
        eDte: '',
      },
    },
    isError: false,
    message: '',
  },
  // updateProfileVideo: {
  //   message: '',
  //   error: false,
  // },
  // updateVideoURL: {
  //   url: '',
  //   isError: false,
  //   message: '',
  // },
  updateHobbies: {
    message: '',
    error: false,
  },
  getHobbies: {
    hobb: [],
    message: '',
    isError: false,
  },
  updateCert: {
    message: '',
    error: false,
  },
  getCert: {
    cert: [],
    message: '',
    isError: false,
  },
  createPostData: {
    fileName: '',
  },
  getPostList: {
    tCnt: 0,
    fCnt: 0,
    postData: [],
    isError: false,
    message: '',
  },
  deletePostDataS3: {
    fileName: '',
  },
  addPostDBData: {
    message: '',
    error: false,
  },
  addFollowData: {
    message: '',
    error: false,
  },
  addCommentData: {
    message: '',
    error: false,
  },
  addLikeData: {
    message: '',
    error: false,
  },
  getCommentList: {
    data: [],
    isError: false,
    message: '',
  },
  getUserLikesList: {
    tCnt: 0,
    fCnt: 0,
    likeUserData: [],
    isError: false,
    message: '',
  },
  updateBatch: {
    message: '',
    error: false,
    data: '',
  },
  getBatchList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      batchData: [
        {
          sDte: '',
          eDte: '',
          acad: '',
          bNme: '',
          crsTp: '',
          BatchCd: '',
          strm: '',
          sNme: '',
        },
      ],
      lastEvalKey: {
        AcadCd: '',
        BatchCd: '',
      },
    },
    isError: false,
    message: '',
  },
  updateCandStatus: {
    message: '',
    error: false,
    data: '',
  },
  getCandList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      candListData: [],
      lastEvalKey: {
        UserTp: '',
        UserCd: '',
      },
    },
    isError: false,
    message: '',
  },
  registerEmailMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerPrsnlMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerAbtMeMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerEmployMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
    data: '',
  },
  registerXcelian: {
    message: '',
    data: '',
    error: false,
  },
  addClient: {
    message: '',
    error: false,
    data: '',
  },
  getMentorList: {
    tCnt: 0,
    fCnt: 0,
    mntrData: [],
    isError: false,
    message: '',
  },
  updateMentorAccess: {
    message: '',
    error: false,
    data: '',
  },
  addClientUser: {
    message: '',
    error: false,
    data: '',
  },
  getClientUserList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      usrData: [],
      lastEvalKey: {
        UserTp: '',
        UserCd: '',
      },
    },
    isError: false,
    message: '',
  },
  delClient: {
    message: '',
    error: false,
  },
  addStaffClient: {
    message: '',
    error: false,
    data: '',
  },
  getStaffClientList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      clntData: [],
      lastEvalKey: {
        ClntCd: '',
        ClntId: '',
      },
    },
    isError: false,
    message: '',
  },
  addNewJob: {
    message: '',
    error: false,
    data: {
      JobCd: '',
      JobId: '',
      NxtStg: '',
    },
  },
  updateJob: {
    message: '',
    error: false,
    data: {
      JobCd: '',
      JobId: '',
      NxtStg: '',
    },
  },
  updateJobStatus: {
    message: '',
    error: false,
    data: '',
  },
  listMetaJobs: {
    metaJobs: [],
    isError: false,
    message: '',
  },
  listPrivateJobs: {
    data: {
      tCnt: 0,
      fCnt: 0,
      jobsData: [],
      lastEvalKey: {
        JobCd: '',
        JobId: '',
      },
    },
    isError: false,
    message: '',
  },
  listPublicJobs: {
    data: {
      tCnt: 0,
      fCnt: 0,
      jobsData: [],
      lastEvalKey: {
        JobCd: '',
        JobId: '',
      },
    },
    isError: false,
    message: '',
  },
  getJobById: {
    data: {
      pStg: '',
      JobCd: '',
      JobId: '',
      nme: '',
      pCd: '',
      wrkPref: '',
      city: '',
      st: '',
      addr: '',
      jobTp: [],
      jobKey: [],
      isCV: false,
      isAppTlne: false,
      appTlne: '',
      schdl: '',
      oSchdl: '',
      isPlndDt: false,
      plndDt: '',
      vacncy: 0,
      timelne: '',
    },
    isError: false,
    message: '',
  },
  getMetaPinCode: {
    data: {
      pin: '',
      cty: '',
      st: '',
    },
    isError: false,
    message: '',
  },
  getJobByDetails: {
    data: {
      pStg: '',
      JobCd: '',
      JobId: '',
      forClnt: '',
      nme: '',
      cNme: '',
      pCd: '',
      wrkPref: '',
      city: '',
      st: '',
      addr: '',
      logoUrl: '',
      jobTp: [],
      schdl: '',
      oSchdl: '',
      isPlndDt: false,
      plndDt: '',
      vacncy: 0,
      timelne: '',
      payBy: '',
      amnt: '',
      amntTp: '',
      suppPay: [],
      oSuppPay: [],
      perk: [],
      oPerk: [],
      jdFile: '',
      jDCntPh: '',
      dailUpdEml: '',
      potCndEml: '',
      isFemale: '',
      isAbled: '',
      skills: [],
      oSkills: [],
      desg: '',
    },
    isError: false,
    message: '',
  },
  listJobsByStatus: {
    data: {
      tCnt: 0,
      fCnt: 0,
      jobsData: [],
      lastEvalKey: {
        JobCd: '',
        JobId: '',
      },
    },
    isError: false,
    message: '',
  },
  listMetaData: {
    data: [],
    isError: false,
    message: '',
  },
  updateInterviewStatus: {
    message: '',
    error: false,
  },
  listXcelianJobs: {
    data: {
      tCnt: 0,
      fCnt: 0,
      jobsData: [],
      lastEvalKey: {
        JobCd: '',
        JobId: '',
      },
    },
    isError: false,
    message: '',
  },
  resumeParser: {
    data: {
      JobCd: '',
      JobId: '',
      forClnt: '',
      jobNme: '',
      uId: '',
      fNme: '',
      lNme: '',
      eId: '',
      phn: '',
      dlCd: '',
    },
    message: '',
    error: false,
  },
  resumeLoad: {
    data: [],
    message: '',
    error: false,
  },
  listRecruitInterview: {
    data: {
      tCnt: 0,
      fCnt: 0,
      recruitIntvData: [],
      lastEvalKey: {
        IntvCd: '',
        IntvId: '',
      },
    },
    isError: false,
    message: '',
  },
  updateRecruitInterviewStatus: {
    message: '',
    error: false,
  },
  xcelianResumeUpdate: {
    message: '',
    error: false,
    jdFileName: '',
  },
  xcelianResumeFileUpdate: {
    message: '',
    error: false,
    jdFileName: '',
  },
  updateNewsletter: {
    message: '',
    error: false,
    fileData: {
      MediaTp: '',
      MediaId: '',
      fileHtmlDesc: '',
      logoFileName: '',
    },
  },
  listNewsletter: {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        MediaTp: '',
        MediaId: '',
      },
      mediaData: [],
    },
    isError: false,
    message: '',
  },
  updateNewsletterStatus: {
    message: '',
    error: false,
    data: {
      id: '',
      message: '',
    },
  },
  getSadminDashboard: {
    message: '',
    isError: false,
    data: {
      subsCount: 0,
      CMPS: 0,
      RCRT: 0,
      CORP: 0,
      XCLN: 0,
      NWSLTR: 0,
      PDCST: 0,
      SKLMENT: 0,
      jobsCount: 0,
    },
  },
  updateSubscriber: {
    message: '',
    error: false,
  },
  getStaffDashboard: {
    message: '',
    isError: false,
    data: {
      jobPending: 0,
      jobLive: 0,
      jobBlock: 0,
    },
  },
};
export type AllAction = ActionType<typeof actions>;
const campusXcelReducer: Reducer<CampusXcelAppState, AllAction> = (
  state: CampusXcelAppState = initialState,
  action: AllAction,
): CampusXcelAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateClientListReq):
      return {
        ...state,
        getClientList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearGetClientReq):
      return {
        ...state,
        getClientList: initialState.getClientList,
      };
    case getType(updateClientUserListReq):
      return {
        ...state,
        getClientUserList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearGetClientUserReq):
      return {
        ...state,
        getClientUserList: initialState.getClientUserList,
      };
    case getType(updateAddClientUserReq):
      return {
        ...state,
        addClientUser: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddClientUserReq):
      return {
        ...state,
        addClientUser: initialState.addClientUser,
      };

    case getType(updateRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: initialState.registerUser,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearLoginToken):
      return {
        ...state,
        genToken: initialState.genToken,
      };
    case getType(updateProfile):
      return {
        ...state,
        getProfileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: action.payload.profileData,
        },
      };
    case getType(clearProfileReq):
      return {
        ...state,
        getProfileData: initialState.getProfileData,
      };
    case getType(updateForgotPassword):
      return {
        ...state,
        forgotPassKeyData: {
          forgotPasswordData: action.payload.forgotPasswordData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(updateFileURLReq):
      return {
        ...state,
        updateFileURL: {
          url: action.payload.url,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateResumeProfile):
      return {
        ...state,
        resumeProfileData: {
          perData: action.payload.perData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeProfile):
      return {
        ...state,
        resumeProfileData: initialState.resumeProfileData,
      };
    case getType(updateUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: initialState.updateUserProfile,
      };
    case getType(updatePassKeyReq):
      return {
        ...state,
        updatePassKey: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPassKeyReq):
      return {
        ...state,
        updateFileURL: initialState.updateFileURL,
      };
    case getType(updateUploadS3FilesReq):
      return {
        ...state,
        uploadS3Files: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearUploadS3FilesReq):
      return {
        ...state,
        uploadS3Files: initialState.uploadS3Files,
      };
    case getType(updateUpdateS3CxMetaReq):
      return {
        ...state,
        updateS3CxMeta: {
          message: action.payload.message,
          success: action.payload.success,
        },
      };
    case getType(clearUpdateS3CxMetaReq):
      return {
        ...state,
        updateS3CxMeta: initialState.updateS3CxMeta,
      };
    case getType(getUpdateS3CxMetaReq):
      return {
        ...state,
        getS3CxMeta: {
          data: action.payload.data,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case getType(clearGetS3CxMetaReq):
      return {
        ...state,
        getS3CxMeta: initialState.getS3CxMeta,
      };
    case getType(updateGetS3FilesReq):
      return {
        ...state,
        getS3Files: {
          data: action.payload.data,
          identifierTp: action.payload.identifierTp,
        },
      };
    case getType(clearGetS3FilesReq):
      return {
        ...state,
        data: initialState.data,
      };
    // case getType(updateUploadVideoReq):
    //   return {
    //     ...state,
    //     updateProfileVideo: {
    //       message: action.payload.message,
    //       error: action.payload.error,
    //     },
    //   };
    // case getType(clearUploadVideoReq):
    //   return {
    //     ...state,
    //     updateProfileVideo: initialState.updateProfileVideo,
    //   };
    case getType(updateObjective):
      return {
        ...state,
        updateObjectiveResume: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearObjective):
      return {
        ...state,
        updateObjectiveResume: initialState.updateObjectiveResume,
      };
    case getType(updateResumeDataObj):
      return {
        ...state,
        getObjectiveResume: {
          obj: action.payload.obj,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeDataObj):
      return {
        ...state,
        getObjectiveResume: initialState.getObjectiveResume,
      };
    case getType(updateResumeVideo):
      return {
        ...state,
        getVideoResume: {
          video: action.payload.video,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeVideo):
      return {
        ...state,
        getVideoResume: initialState.getVideoResume,
      };
    case getType(updateVideo):
      return {
        ...state,
        updateVideoResume: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearVideo):
      return {
        ...state,
        updateVideoResume: initialState.updateVideoResume,
      };
    case getType(updateOrgProject):
      return {
        ...state,
        updateOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearOrgProject):
      return {
        ...state,
        updateOrgProj: initialState.updateOrgProj,
      };
    case getType(updateListOrgProj):
      return {
        ...state,
        listOrgProj: {
          isError: action.payload.isError,
          message: action.payload.message,
          orgProj: action.payload.orgProj,
        },
      };
    case getType(updateDelOrgProjReq):
      return {
        ...state,
        deleteOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(updatePreviewResume):
      return {
        ...state,
        previewResume: {
          allData: action.payload.allData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    // case getType(updateVideoURLReq):
    //   return {
    //     ...state,
    //     updateVideoURL: {
    //       url: action.payload.url,
    //       isError: action.payload.isError,
    //       message: action.payload.message,
    //     },
    //   };
    // case getType(clearVideoURLReq):
    //   return {
    //     ...state,
    //     updateVideoURL: initialState.updateVideoURL,
    //   };
    case getType(updateHobbies):
      return {
        ...state,
        updateHobbies: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearHobbies):
      return {
        ...state,
        updateHobbies: initialState.updateHobbies,
      };
    case getType(updateResultHobbies):
      return {
        ...state,
        getHobbies: {
          hobb: action.payload.hobb,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResultHobbies):
      return {
        ...state,
        getHobbies: initialState.getHobbies,
      };
    case getType(updateCert):
      return {
        ...state,
        updateCert: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearCert):
      return {
        ...state,
        updateCert: initialState.updateCert,
      };
    case getType(updateResultCert):
      return {
        ...state,
        getCert: {
          cert: action.payload.cert,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResultCert):
      return {
        ...state,
        getCert: initialState.getCert,
      };
    case getType(updatePostPublish):
      return {
        ...state,
        createPostData: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearPostPublish):
      return {
        ...state,
        createPostData: initialState.createPostData,
      };
    case getType(updatePostListReq):
      return {
        ...state,
        getPostList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          postData: action.payload.postData,
          message: action.payload.message,
        },
      };
    case getType(clearPostListReq):
      return {
        ...state,
        getPostList: initialState.getPostList,
      };
    case getType(updateDelPostPublish):
      return {
        ...state,
        deletePostDataS3: {
          fileName: action.payload.fileName,
        },
      };
    case getType(updatePostDBReq):
      return {
        ...state,
        addPostDBData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearPostDBReq):
      return {
        ...state,
        addPostDBData: initialState.addPostDBData,
      };
    case getType(updateLikeReq):
      return {
        ...state,
        addLikeData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearLikeReq):
      return {
        ...state,
        addLikeData: initialState.addLikeData,
      };
    case getType(updateCommentReq):
      return {
        ...state,
        addCommentData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearCommentReq):
      return {
        ...state,
        addCommentData: initialState.addCommentData,
      };
    case getType(updateFollowReq):
      return {
        ...state,
        addFollowData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearFollowReq):
      return {
        ...state,
        addFollowData: initialState.addFollowData,
      };
    case getType(updateCommentListReq):
      return {
        ...state,
        getCommentList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearCommentListReq):
      return {
        ...state,
        getCommentList: initialState.getCommentList,
      };
    case getType(updateUserLikesListReq):
      return {
        ...state,
        getUserLikesList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          likeUserData: action.payload.likeUserData,
          message: action.payload.message,
        },
      };
    case getType(clearUserLikesListReq):
      return {
        ...state,
        getUserLikesList: initialState.getUserLikesList,
      };
    case getType(updateBatchReq):
      return {
        ...state,
        updateBatch: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearBatchReq):
      return {
        ...state,
        updateBatch: initialState.updateBatch,
      };
    case getType(updateBatchListReq):
      return {
        ...state,
        getBatchList: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(updateCandStsReq):
      return {
        ...state,
        updateCandStatus: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearCandStsReq):
      return {
        ...state,
        updateCandStatus: initialState.updateCandStatus,
      };
    case getType(updateCandidatesListReq):
      return {
        ...state,
        getCandList: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearCandidatesListReq):
      return {
        ...state,
        getCandList: initialState.getCandList,
      };
    case getType(updateRegisterEmailMNTRReq):
      return {
        ...state,
        registerEmailMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterEmailMNTRReq):
      return {
        ...state,
        registerEmailMentor: initialState.registerEmailMentor,
      };
    case getType(updateRegisterPrsnlMNTRReq):
      return {
        ...state,
        registerPrsnlMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterPrsnlMNTRReq):
      return {
        ...state,
        registerPrsnlMentor: initialState.registerPrsnlMentor,
      };
    case getType(updateRegisterAbtMeMNTRReq):
      return {
        ...state,
        registerAbtMeMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterAbtMeMNTRReq):
      return {
        ...state,
        registerAbtMeMentor: initialState.registerAbtMeMentor,
      };
    case getType(updateRegisterEmployMNTRReq):
      return {
        ...state,
        registerEmployMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
          data: action.payload.data,
        },
      };
    case getType(clearRegisterEmployMNTRReq):
      return {
        ...state,
        registerEmployMentor: initialState.registerEmployMentor,
      };
    case getType(updateRegisterXCLNPostReq):
      return {
        ...state,
        registerXcelian: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterXCLNPostReq):
      return {
        ...state,
        registerXcelian: initialState.registerXcelian,
      };
    case getType(updateAddClientReq):
      return {
        ...state,
        addClient: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddClientReq):
      return {
        ...state,
        addClient: initialState.addClient,
      };
    case getType(updateMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          mntrData: action.payload.mntrData,
          message: action.payload.message,
        },
      };
    case getType(clearMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: false,
          tCnt: 0,
          fCnt: 0,
          mntrData: [],
          message: '',
        },
      };
    case getType(updateMentorAccess):
      return {
        ...state,
        updateMentorAccess: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearMentorAccess):
      return {
        ...state,
        updateMentorAccess: initialState.updateMentorAccess,
      };
    case getType(updateDelClientReq):
      return {
        ...state,
        delClient: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearDelClientReq):
      return {
        ...state,
        delClient: initialState.delClient,
      };
    case getType(updateAddStaffClientReq):
      return {
        ...state,
        addStaffClient: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddStaffClientReq):
      return {
        ...state,
        addStaffClient: initialState.addStaffClient,
      };
    case getType(updateStaffClientListReq):
      return {
        ...state,
        getStaffClientList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearGetStaffClientReq):
      return {
        ...state,
        getStaffClientList: initialState.getStaffClientList,
      };
    case getType(updateAddNewJobReq):
      return {
        ...state,
        addNewJob: {
          error: action.payload.error,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearAddNewJobReq):
      return {
        ...state,
        addNewJob: initialState.addNewJob,
      };
    case getType(updateEditJobsReq):
      return {
        ...state,
        updateJob: {
          error: action.payload.error,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearEditJobsReq):
      return {
        ...state,
        updateJob: initialState.updateJob,
      };
    case getType(updateJobStatusReq):
      return {
        ...state,
        updateJobStatus: {
          error: action.payload.error,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearJobStatusReq):
      return {
        ...state,
        updateJobStatus: initialState.updateJobStatus,
      };
    case getType(updateListMetaJobReq):
      return {
        ...state,
        listMetaJobs: {
          isError: action.payload.isError,
          message: action.payload.message,
          metaJobs: action.payload.metaJobs,
        },
      };
    case getType(clearListMetaJobReq):
      return {
        ...state,
        listMetaJobs: initialState.listMetaJobs,
      };
    case getType(updateListPrivateJobReq):
      return {
        ...state,
        listPrivateJobs: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListPrivateJobReq):
      return {
        ...state,
        listPrivateJobs: initialState.listPrivateJobs,
      };
    case getType(updateListPublicJobReq):
      return {
        ...state,
        listPublicJobs: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListPublicJobReq):
      return {
        ...state,
        listPublicJobs: initialState.listPublicJobs,
      };
    case getType(updatejobByIdReq):
      return {
        ...state,
        getJobById: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearjobByIdReq):
      return {
        ...state,
        getJobById: initialState.getJobById,
      };
    case getType(updateJobByDetailsReq):
      return {
        ...state,
        getJobByDetails: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearJobByDetailsReq):
      return {
        ...state,
        getJobByDetails: initialState.getJobByDetails,
      };
    case getType(updateMetaPinCodeReq):
      return {
        ...state,
        getMetaPinCode: {
          data: action.payload.data,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearMetaPinCodeReq):
      return {
        ...state,
        getMetaPinCode: initialState.getMetaPinCode,
      };
    case getType(updateListJobsByStatusReq):
      return {
        ...state,
        listJobsByStatus: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListJobsByStatusReq):
      return {
        ...state,
        listJobsByStatus: initialState.listJobsByStatus,
      };
    case getType(updateListMETAReq):
      return {
        ...state,
        listMetaData: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListMETAReq):
      return {
        ...state,
        listMetaData: initialState.listMetaData,
      };
    case getType(updateInterviewStatus):
      return {
        ...state,
        updateInterviewStatus: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearInterviewStatus):
      return {
        ...state,
        updateInterviewStatus: initialState.updateInterviewStatus,
      };
    case getType(updateListXcelianJobReq):
      return {
        ...state,
        listXcelianJobs: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListXcelianJobReq):
      return {
        ...state,
        listXcelianJobs: initialState.listXcelianJobs,
      };
    case getType(updateResumeParser):
      return {
        ...state,
        resumeParser: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearResumeParser):
      return {
        ...state,
        resumeParser: initialState.resumeParser,
      };
    case getType(updateResumeLoad):
      return {
        ...state,
        resumeLoad: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearResumeLoad):
      return {
        ...state,
        resumeLoad: initialState.resumeLoad,
      };
    case getType(updateListRecruitInterviewReq):
      return {
        ...state,
        listRecruitInterview: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListRecruitInterviewReq):
      return {
        ...state,
        listRecruitInterview: initialState.listRecruitInterview,
      };
    case getType(updateRecruitInterviewStatus):
      return {
        ...state,
        updateRecruitInterviewStatus: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearRecruitInterviewStatus):
      return {
        ...state,
        updateRecruitInterviewStatus: initialState.updateRecruitInterviewStatus,
      };
    case getType(updateXclnResumePutReq):
      return {
        ...state,
        xcelianResumeUpdate: {
          error: action.payload.error,
          message: action.payload.message,
          jdFileName: action.payload.jdFileName,
        },
      };
    case getType(clearXclnResumePutReq):
      return {
        ...state,
        xcelianResumeUpdate: initialState.xcelianResumeUpdate,
      };
    case getType(updateXclnResumeFilePutReq):
      return {
        ...state,
        xcelianResumeFileUpdate: {
          error: action.payload.error,
          message: action.payload.message,
          jdFileName: action.payload.jdFileName,
        },
      };
    case getType(clearXclnResumeFilePutReq):
      return {
        ...state,
        xcelianResumeFileUpdate: initialState.xcelianResumeFileUpdate,
      };
    case getType(updateEditNewsletterReq):
      return {
        ...state,
        updateNewsletter: {
          error: action.payload.error,
          message: action.payload.message,
          fileData: action.payload.fileData,
        },
      };
    case getType(clearEditNewsletterReq):
      return {
        ...state,
        updateNewsletter: initialState.updateNewsletter,
      };
    case getType(updateListNewsletterReq):
      return {
        ...state,
        listNewsletter: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearListNewsletterReq):
      return {
        ...state,
        listNewsletter: initialState.listNewsletter,
      };
    case getType(updateEditNewsletterStatusReq):
      return {
        ...state,
        updateNewsletterStatus: {
          error: action.payload.error,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearEditNewsletterStatusReq):
      return {
        ...state,
        updateNewsletterStatus: initialState.updateNewsletterStatus,
      };
    case getType(updateGetSadminDashReq):
      return {
        ...state,
        getSadminDashboard: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearGetSadminDashReq):
      return {
        ...state,
        getSadminDashboard: initialState.getSadminDashboard,
      };
    case getType(updateEditSubscriberReq):
      return {
        ...state,
        updateSubscriber: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearEditSubscriberReq):
      return {
        ...state,
        updateSubscriber: initialState.updateSubscriber,
      };
    case getType(updateGetStaffDashReq):
      return {
        ...state,
        getStaffDashboard: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearGetStaffDashReq):
      return {
        ...state,
        getStaffDashboard: initialState.getStaffDashboard,
      };
    default:
      return state;
  }
};
export default campusXcelReducer;
